
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearIcon from '@mui/icons-material/Clear';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import {ThreeDots, Watch} from "react-loader-spinner";


import useEntityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useRegistrationService, { useBasicFilterRegistration } from './services/Registration';


import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Slide, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IRegistration, IRegistrationMaterial, defaultRegistration } from './models/Registration';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_SCHOOL_MATERIAL, Enum_SCHOOL_MATERIAL_STATUS,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_ARTICLE_OPTION, Enum_STUDENT_FROM } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass, ISchoolYearClassTemplate } from 'features/setup/models/SchoolYear';

import useBusDestinationService, { useBasicFilterBusDestination } from 'features/setup/services/BusDestination';
import { IBusDestination } from 'features/setup/models/BusDestination';

import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';
import { IPerson } from './models/Person';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import useBillingTypeService, { useBasicFilterBillingType, useBasicFilterArticleBillingType, useBasicFilterTransportBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { debounce } from 'lodash';
import { GrClose, GrSearch, GrSearchAdvanced } from 'react-icons/gr';
import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

import { Colors } from 'themes/theme';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ITellerOperation } from 'features/finance/models/TellerOperation';
import { Print } from '@mui/icons-material';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';


export const RegistrationForm: FC<IRegistration> = (props: IRegistration = defaultRegistration) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = useEntityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createRegistration, updateRegistration, 
          generateIntialRegistration, evaluateAddedBillings,
          createNewBillings, createNewArticleBillings, createNewTransportBillings, changeClass } = useRegistrationService();

  const { getSchoolYearClasses, getSchoolYearClassTemplates } = useSchoolYearService();

  const { getPersons, getPersonsSearchCount, getParentPortables, getParentEmails } = usePersonService();

  const {getAvailableArticleBillingTypes} = useBillingTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);
  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterRegistration = useBasicFilterRegistration( 
    (event: React.MouseEvent<unknown>, row: IRegistration) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, gender, civility} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
        setValue('gender', gender);  
        setValue('civility', civility);  
                          
        setOpenPersonFilter(false);
      }
  );

  const [openBusDestinationFilter, setOpenBusDestinationFilter] = useState(false);
  const basicFilterBusDestination = useBasicFilterBusDestination( 
      (event: React.MouseEvent<unknown>, row: IBusDestination) => {
          const {id, name} = row;
        
        setValue('busDestinationId', id);
        setValue('busDestinationName', name);
                                  
        setOpenBusDestinationFilter(false);
      }
  );
  

  const [selectedFilterBillingTypes, setSelectedFilterBillingTypes] = useState<number[]>([]);
  const [filteredBillingTypes, setFilteredBillingTypes] = useState<IBillingType[]>([]);
  
  const [openBillingTypeFilter, setOpenBillingTypeFilter] = useState(false);
  const basicFilterBillingType = useBasicFilterBillingType( 
      async (event: React.MouseEvent<unknown>, row: IBillingType) => {
          const {name, description, id, isArticle, isTransport} = row;

        
        if(isArticle) {
          enqueueSnackbar( t('Articles can not be added here'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }    
        
        if(isTransport) {
          enqueueSnackbar( t('Transport can not be added here'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        if(currentBillingUsage === 'billing')
          (refAppendBillings.current??emptyFunc)(
            {id:0, registrationId: _id, billingTypeId: id, 
              isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
              billingTypeName: name,  
              amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
        else {
          // setFilteredBillingTypes([{...row}]);
          // setSelectedFilterBillingTypes([id]);
          // await handleOkBasicTextFilterBillingTypeForm();
          await evaluateNewBillingsAdded([{...row}], [id]);
        }
        // (refAppendNewBillings.current??emptyFunc)(
        //   {id:0, registrationId: _id, billingTypeId: id, 
        //     isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
        //     billingTypeName: name, articleOption: '', articleQuantity: 0, 
        //     amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
                           
        //   setOpenBillingTypeFilter(false);
      }, {
        rowCheckedMode: 'multiple', 
        stateSelected: [selectedFilterBillingTypes, setSelectedFilterBillingTypes],
        stateFiltered: [filteredBillingTypes, setFilteredBillingTypes],
    }
  );

  const [selectedFilterArticleBillingTypes, setSelectedFilterArticleBillingTypes] = useState<number[]>([]);
  const [filteredArticleBillingTypes, setFilteredArticleBillingTypes] = useState<IBillingType[]>([]);

  const [openArticleBillingTypeFilter, setOpenArticleBillingTypeFilter] = useState(false);
  const basicFilterArticleBillingType = useBasicFilterArticleBillingType( 
    async (event: React.MouseEvent<unknown>, row: IBillingType) => {
        const {name, description, id, deliveryArticleId} = row;

      
      if(currentBillingUsage === 'billing') {
        (refAppendBillings.current??emptyFunc)(
          {id:0, registrationId: _id, billingTypeId: id, 
            isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
            billingTypeName: name,  
            amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
            setOpenArticleBillingTypeFilter(false);

          } else {
            // setFilteredArticleBillingTypes([{...row}]);
            // setSelectedFilterArticleBillingTypes([deliveryArticleId]);

            // await handleOkBasicTextFilterArticleBillingTypeForm();
            await evaluateNewArticleBillingsAdded([{...row}], [deliveryArticleId]);
      }
      // (refAppendNewBillings.current??emptyFunc)(
      //   {id:0, registrationId: _id, billingTypeId: id, 
      //     isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
      //     billingTypeName: name, articleOption: '', articleQuantity: 0, 
      //     amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
                         
        
    }, {
      rowCheckedMode: 'multiple', 
      stateSelected: [selectedFilterArticleBillingTypes, setSelectedFilterArticleBillingTypes],
      stateFiltered: [filteredArticleBillingTypes, setFilteredArticleBillingTypes],
    }
  );

  const [selectedFilterTransportBillingTypes, setSelectedFilterTransportBillingTypes] = useState<number[]>([]);
  const [filteredTransportBillingTypes, setFilteredTransportBillingTypes] = useState<IBillingType[]>([]);

  const [openTransportBillingTypeFilter, setOpenTransportBillingTypeFilter] = useState(false);
  const basicFilterTransportBillingType = useBasicFilterTransportBillingType( 
    async (event: React.MouseEvent<unknown>, row: IBillingType) => {
        const {name, description, id} = row;

      if(currentBillingUsage === 'billing') {
        (refAppendBillings.current??emptyFunc)(
          {id:0, registrationId: _id, billingTypeId: id, 
            isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
            billingTypeName: name,  
            amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
            setOpenTransportBillingTypeFilter(false);
      } else {
        
        // setFilteredTransportBillingTypes([{...row}]);
        // setSelectedFilterTransportBillingTypes([id]);

        // await handleOkBasicTextFilterTransportBillingTypeForm();
        await evaluateNewTransportBillingsAdded([{...row }], [id]);
      }
                               
        
    }, {
      rowCheckedMode: 'multiple', 
      stateSelected: [selectedFilterTransportBillingTypes, setSelectedFilterTransportBillingTypes],
      stateFiltered: [filteredTransportBillingTypes, setFilteredTransportBillingTypes],
    }
  );
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IRegistration>({defaultValues:defaultRegistration});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const watchSchoolYearId = watch('schoolYearId');
  const watchStudyType = watch('studyType');
  const watchStudyLanguage = watch('studyLanguage');
  const watchStudentFrom = watch('studentFrom');

  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');

  const watchSchoolYearClassId = watch('schoolYearClassId');
  
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchParticularSign = watch('particularSign');
  const watchPersonId = watch('personId');


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
   

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const {firstName, lastName, particularSign} = getValues();
        return {firstName, lastName, particularSign};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, civility, gender, studentCode} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  

        setValue('civility', civility);
        setValue('gender', gender);
        setValue('studentCode', studentCode);
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IRegistration>,Error,IRegistration>(
      _id>0?updateRegistration:createRegistration, {   
        onSuccess: (data: IResult<IRegistration>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          setOpenNewBilling(false);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Registration')} - # ${data.data.id} # - ${data.data.isRepeater? `(${t('Repeater')})`: '' }` );
          queryClient.invalidateQueries(['Registration',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
          setOpenNewBilling(false);
        }
      });

      //// Mutation for actions.....
      const {isLoading: isLoadingNewArticle, isError: isErrorNewArticle, isSuccess:isSuccessNewArticle, error: errorNewArticle, 
                mutate: mutateNewArticles } = useMutation<IResult<IRegistration>,Error,{id: number, billings: IBilling[]}>(
          createNewArticleBillings, {   
          onSuccess: (data: IResult<IRegistration>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
           
            //_setId(data.data.id);
            
            reset(data.data);
            setOpenNewArticleBilling(false);
            queryClient.invalidateQueries(['Registration',data.data.id]);
          },
          onError: (err: Error) => {          
            
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              setOpenNewArticleBilling(false);
          }
        });

      const {isLoading: isLoadingNewTransport, isError: isErrorNewTransport, isSuccess:isSuccessNewTransport, error: errorNewTransport, 
          mutate: mutateNewTransports } = useMutation<IResult<IRegistration>,Error,{id: number, billings: IBilling[]}>(
          createNewTransportBillings, {   
          onSuccess: (data: IResult<IRegistration>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
            //_setId(data.data.id);
            setIsSaveLoading(false);
            reset(data.data);
            setOpenNewTransportBilling(false);
            queryClient.invalidateQueries(['Registration',data.data.id]);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenNewTransportBilling(false);
            setIsSaveLoading(false);
          }
        });

      const {isLoading: isLoadingNewBilling, isError: isErrorNewBilling, isSuccess:isSuccessNewBilling, error: errorNewBilling, 
          mutate: mutateNewBillings } = useMutation<IResult<IRegistration>,Error,{id: number, billings: IBilling[]}>(
          createNewBillings, {   
          onSuccess: (data: IResult<IRegistration>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
            //_setId(data.data.id);
            setIsSaveLoading(false);
            reset(data.data);
            setOpenNewTransportBilling(false);
            queryClient.invalidateQueries(['Registration',data.data.id]);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenNewTransportBilling(false);
            setIsSaveLoading(false);
          }
        });


        const {isLoading: isLoadingChangeClass, isError: isErrorChangeClass, isSuccess:isSuccessChangeClass, error: errorChangeClass, 
          mutate: mutateChangeClass } = useMutation<IResult<IRegistration>,Error,{id: number, newClassId: number}>(
            changeClass, {   
          onSuccess: (data: IResult<IRegistration>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
            //_setId(data.data.id);
            setIsSaveLoading(false);
            reset(data.data);
            setOpenChangeClass(false);
            queryClient.invalidateQueries(['Registration',data.data.id]);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenChangeClass(false);
            setIsSaveLoading(false);
          }
        });


    const {data: _data, refetch} = useQuery<IRegistration>(['Registration', _id], () => retrieveEntity('Registration',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: extensionEnumerationItems } = useQuery<IEnumerationItem[]>(['SchoolYear','EnumerationItem', watchSchoolYearId], () =>
      getExtensionEnumerationItemsBySchoolYear(watchSchoolYearId) );

    const {data: enumItems, refetch : refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Registration'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_ARTICLE_OPTION, Enum_STUDENT_FROM,
              Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_SCHOOL_MATERIAL, Enum_SCHOOL_MATERIAL_STATUS ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    
    const [schoolYearClassTemplates, setSchoolYearClassTemplates] = useState<ISchoolYearClassTemplate[]>([]);
    const [classes, setClasses] = useState<ISchoolYearClass[]>([]);

    const [openBillingExtension, setOpenBillingExtension] = useState<boolean>(false);

    const [openArticleAvailable, setOpenArticleAvailable] = useState<boolean>(false);
    const [openBillingOption, setOpenBillingOption] = useState<boolean>(false);
    const getInputAdornmentBillingOption = (row: IBilling, cellId: keyof IBilling)  => ({
 
      toolTip: 'Details',
      icon: MoreIcon,
      iconDisable: !row.isArticle,
      onClickIcon: (event: any, index: number, row: IBilling ) => {    
        
        setBillingIndex(index);
        setCurrentBilling(row);
                
        setOpenBillingOption(true);
      }  
    })

    const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([]);
    useEffect(() => {
    setHeadBillingCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 40,
        getInputAdornment: getInputAdornmentBillingOption  },
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
      {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
      {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},
      {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 15},
    ]  )
  }, [t,i18n])

  const refAppendBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveBilling = useRef<(index: number ) => void>(null);

  const [currentBilling, setCurrentBilling] = useState<IBilling>(defaultBilling);
  const [billingIndex, setBillingIndex] = useState<number>(-1);
  const billingRowActionIcon = ( billing: IBilling) : ActionIconTableRow<IRegistration,IBilling> => {
  
    const res: ActionIconTableRow<IRegistration,IBilling> = {
      toolTip: 'Remove',
      icon: SystemUpdateAltIcon,
      hasAction: (index: number,row: IBilling) => !row.isCancelled && row.isArticle, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBilling) => {
        
        const billing = getValues().billings.at(index);
        if(isFalsy(billing)) return;

        openEntityActionDrawer('Billing', billing.id);           
      }
    }
    return res;
  }

  const handleBillingDoubleClick = (event: React.MouseEvent<unknown>, index: number,row: IBilling) => {
    const roleEntityBilling = roleEntities.find(re => re.entityName === 'Billing');
    if(isFalsy(roleEntityBilling)) return;
        
    const billing = getValues().billings.at(index);
    if(isFalsy(billing)) return;
    
    navigate(`/billing/${billing.id}`);
  }

  const [optionAvailableArticles, setOptionAvailableArticles] = useState<IBillingType[]>([]);
  const handleArticleAvailableOption = async (event: any) => {

    const {billingTypeName, billingTypeId} = currentBilling;    
    const arr = await getAvailableArticleBillingTypes( {code: '', name:billingTypeName, description: '' } );
    const arrAvailableArticles = arr.filter( x => x.id === billingTypeId);
    setOptionAvailableArticles(arrAvailableArticles);
    setOpenArticleAvailable(true);
  }

  const handleOptionAvailableArticleDoubleClick = (event: React.MouseEvent<unknown>,row: IBillingType) => {

      const billing = getValues().billings.at(billingIndex);
      if(billingIndex < 0 || isFalsy(billing)) return;

      const {deliveryArticleId, articleOption, articleOptionName, deliveryDescription} = row;
      (refUpdateBilling.current??emptyFunc)(billingIndex, {...billing!, 
        deliveryArticleId, articleOption, articleOptionName, deliveryDescription});
      
      setOpenArticleAvailable(false);
  }
  

  const getArticleOptionList = (row: IBilling, cellId: keyof IBilling, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_ARTICLE_OPTION);
  }

  const cellEditableNewBilling = (row: IBilling, cellId: keyof IBilling) => {
    return true;
  }

  const [headNewBillingCells, setHeadNewBillingCells]  = useState<HeadCell<IBilling>[]>([]);
    useEffect(() => {
    setHeadNewBillingCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 75,  },
      // {id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 35, 
      //   isEditable: cellEditableNewBilling, getOptions: getArticleOptionList},
      // {id:'articleQuantity', label : t('Quantity'),  display: true, type: 'numeric', width: 15,
      //   isEditable: cellEditableNewBilling},
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 25},
      
    ]  )
  }, [t,i18n])

  const refAppendNewBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateNewBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveNewBilling = useRef<(index: number ) => void>(null);

  const [newBillingIndex, setNewBillingIndex] = useState<number>(-1);
  const newBillingRowActionIcon = ( billing: IBilling) : ActionIconTableRow<IRegistration,IBilling> => {
  
    const res: ActionIconTableRow<IRegistration,IBilling> = {
      toolTip: 'Remove',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IBilling) => true, //!row.isObligatory, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBilling) => {
        
        (refRemoveNewBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }


  const [headNewArticleBillingCells, setHeadNewArticleBillingCells]  = useState<HeadCell<IBilling>[]>([]);
    useEffect(() => {
    setHeadNewArticleBillingCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 45,  },
      {id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 35, getOptions: getArticleOptionList},
      // {id:'articleQuantity', label : t('Quantity'),  display: true, type: 'numeric', width: 15,
      //   isEditable: cellEditableNewBilling},
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
      
    ]  )
  }, [t,i18n])

  const refAppendNewArticleBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateNewArticleBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveNewArticleBilling = useRef<(index: number ) => void>(null);

  const newArticleBillingRowActionIcon = ( billing: IBilling) : ActionIconTableRow<IRegistration,IBilling> => {
  
    const res: ActionIconTableRow<IRegistration,IBilling> = {
      toolTip: 'Remove',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IBilling) => !row.isObligatory, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBilling) => {
        
        (refRemoveNewArticleBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }


  const [headNewTransportBillingCells, setHeadNewTransportBillingCells]  = useState<HeadCell<IBilling>[]>([]);
    useEffect(() => {
    setHeadNewTransportBillingCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 50, },
      {id:'isArrival', label : t('Arrival ?'),  display: true, type: 'boolean', width: 15, },
      {id:'isDeparture', label : t('Departure ?'),  display: true, type: 'boolean', width: 15, },

      //{id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 35, getOptions: getArticleOptionList},
      // {id:'articleQuantity', label : t('Quantity'),  display: true, type: 'numeric', width: 15,
      //   isEditable: cellEditableNewBilling},
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
      
    ]  )
  }, [t,i18n])

  const refAppendNewTransportBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateNewTransportBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveNewTransportBilling = useRef<(index: number ) => void>(null);

  const newTransportBillingRowActionIcon = ( billing: IBilling) : ActionIconTableRow<IRegistration,IBilling> => {
  
    const res: ActionIconTableRow<IRegistration,IBilling> = {
      toolTip: 'Remove',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IBilling) => !row.isObligatory, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBilling) => {
        
        (refRemoveNewTransportBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }

  const getMaterialList = (row: IRegistrationMaterial, cellId: keyof IRegistrationMaterial, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_MATERIAL);
  }

  const getMaterialStatusList = (row: IRegistrationMaterial, cellId: keyof IRegistrationMaterial, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_MATERIAL_STATUS);
  }

  const [headRegistrationMaterialCells, setHeadRegistrationMaterialCells]  = useState<HeadCell<IRegistrationMaterial>[]>([]);
  useEffect(() => {
    setHeadRegistrationMaterialCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'schoolMaterialCode', label : t('Name'),  display: true, type: 'string', width: 45,  
        getOptions: getMaterialList  },
      
      // {id:'isGivenByStudent', label : `${t('Given by student ?')}`,  display: true, type: 'boolean', width: 15, 
      //   isEditable: cellEditableMaterial },
      {id:'status', label : `${t('Status')}`,  display: true, type: 'string', width: 35, getOptions: getMaterialStatusList },
      {id:'statusDate', label : `${t('Date')}`,  display: true, type: 'date', width: 20, },
    ]  )
  }, [t,i18n])

  const refAppendRegistrationMaterials = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRegistrationMaterial = useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
  const refRemoveRegistrationMaterial = useRef<(index: number ) => void>(null);
  

  const [registrationMaterialIndex, setRegistrationMaterialIndex] = useState<number>(-1);
  const registrationMaterialRowActionIcon = ( registrationMaterial: IRegistrationMaterial) : ActionIconTableRow<IRegistration,IRegistrationMaterial> => {
  
    const res: ActionIconTableRow<IRegistration,IRegistrationMaterial> = {
      toolTip: 'remove',
      icon: registrationMaterial.status === '95' ? DoneOutlineIcon : ClearIcon,
      hasAction: (index: number,row: IRegistrationMaterial) => {
        const roleEntityRegistrationMaterial = roleEntities.find(re => re.entityName === 'RegistrationMaterial');
        return !(isFalsy(roleEntityRegistrationMaterial));
      }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistrationMaterial) => {        
        
        const regMaterial = getValues().registrationMaterials.at(index);
        if(isFalsy(regMaterial)) return;

        openEntityActionDrawer('RegistrationMaterial', regMaterial.id);
        //(refRemoveRegistrationMaterial.current??emptyFunc)(index);            
      }
    }
    return res;
  }


  const tellerOperationRowActionIcon = ( tellerOperation: ITellerOperation) : ActionIconTableRow<IRegistration,ITellerOperation> => {
  
    const res: ActionIconTableRow<IRegistration,ITellerOperation> = {
      toolTip: t('Print'),
      icon: Print,
      hasAction: (index: number,row: ITellerOperation) => {
        const roleEntityTellerOperation = roleEntities.find(re => re.entityName === 'TellerOperation');
        return roleEntityTellerOperation?.printAllowed ?? false;
      }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ITellerOperation) => {
        openEntityPrintDrawer('TellerOperation', row.id);
        //(refRemoveBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }

  const handleTellerOperationDoubleClick = (event: React.MouseEvent<unknown>, row: ITellerOperation) => {
    const roleEntityTellerOperation = roleEntities.find(re => re.entityName === 'TellerOperation');
    if(isFalsy(roleEntityTellerOperation)) return;
    
    navigate(`/tellerOperation/${row.id}`);
  }

  type BillingUsage = 'billing' | 'new-billing';

  const [currentBillingUsage, setCurrentBillingUsage] = useState<BillingUsage>('billing');
  
  const handleAddBillings = (event: any) => {
    setCurrentBillingUsage('billing')
    setOpenBillingTypeFilter(true);
  }

  const handleAddArticleBillings = (event: any) => {
    setCurrentBillingUsage('billing')
    setOpenArticleBillingTypeFilter(true);
  }  

  const handleAddNewBillings = (event: any) => {
    setCurrentBillingUsage('new-billing');
    setOpenBillingTypeFilter(true);
  }

  const handleAddNewArticleBillings = (event: any) => {
    setCurrentBillingUsage('new-billing');
    setOpenArticleBillingTypeFilter(true);
  }

  const handleAddNewTransportBillings = (event: any) => {

    const {isArrival, isDeparture, busDestinationId} = getValues();

    if( !isArrival && !isDeparture ) {
      enqueueSnackbar( t('You have to specify departure or arrival'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
      return;
    }

    if(busDestinationId <= 0) {
      enqueueSnackbar( t('You have to specify destination'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
      return;
    }

    setCurrentBillingUsage('new-billing');
    setOpenTransportBillingTypeFilter(true);
  }
  

  const handleOkBasicTextFilterBillingTypeForm = async () => {

    if(selectedFilterBillingTypes.length === 0) {
      enqueueSnackbar( t('No billing to add'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
      setOpenBillingTypeFilter(false);
      return;
    } 
     
    await evaluateNewBillingsAdded(filteredBillingTypes, selectedFilterBillingTypes);
  }

  const evaluateNewBillingsAdded = async (billingTypes: IBillingType[], selectBillingTypes: number[]) => {
    if(billingTypes.some( x => x.isArticle) ) {
      enqueueSnackbar( t('Articles can not be added here'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

    if(billingTypes.some( x => x.isTransport) ) {
      enqueueSnackbar( t('Transport can not be added here'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

    const billings2Append = billingTypes.filter(ct => selectBillingTypes.includes(ct.id || 0) &&
      getValues().newBillings.findIndex( b => b.billingTypeId === ct.id) < 0 );

    const resultRegistration = await evaluateAddedBillings({...getValues(),
        billings: billings2Append.map( (x) => ({...x, billingTypeId: x.id, billingExtensions: []}) ) });

    if(!resultRegistration.succeeded) {
      enqueueSnackbar( resultRegistration.messages.join('\n'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 });
      return;
    }

    if(currentBillingUsage === 'billing')
      (refAppendBillings.current??emptyFunc)( resultRegistration.data.billings);
    else
      (refAppendNewBillings.current??emptyFunc)( resultRegistration.data.billings);
  
    setOpenBillingTypeFilter(false);
  }



  const handleOkBasicTextFilterArticleBillingTypeForm = async () => {
    if(selectedFilterArticleBillingTypes.length === 0) {
      enqueueSnackbar( t('No billing to add'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
        setOpenArticleBillingTypeFilter(false);
      return;
    } 

    await evaluateNewArticleBillingsAdded(filteredArticleBillingTypes, selectedFilterArticleBillingTypes);
  }

  const evaluateNewArticleBillingsAdded = async (billingTypes: IBillingType[], selectBillingTypes: number[]) => {
    if(billingTypes.some( x => !x.isArticle) ) {
      enqueueSnackbar( t('Articles can not be added here'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

    const billings2Append = billingTypes.filter(art => selectBillingTypes.includes(art.deliveryArticleId || 0) );
    
    const resultRegistration = await evaluateAddedBillings({...getValues(),
        billings: billings2Append.map( (x) => ({...x, billingTypeId: x.id, billingExtensions: []}) ) } );
    
    if(!resultRegistration.succeeded) {
      enqueueSnackbar( resultRegistration.messages.join('\n'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 });
      return;
    }

    const arrBillings = resultRegistration.data.billings.map( (x) => {
      const articleBillingType = billings2Append.find(y => y.deliveryArticleId === x.deliveryArticleId);
      
      return {...x, isArticle: true, 
        deliveryArticleId: isFalsy(articleBillingType)? 0: articleBillingType!.deliveryArticleId,
        articleOption: isFalsy(articleBillingType)? '': articleBillingType!.articleOption }} );

    if(currentBillingUsage === 'billing')
      (refAppendBillings.current??emptyFunc)( arrBillings);
    else
      (refAppendNewArticleBillings.current??emptyFunc)( arrBillings);

    setOpenArticleBillingTypeFilter(false);

  }

  const handleOkBasicTextFilterTransportBillingTypeForm  = async () => {

    if(selectedFilterTransportBillingTypes.length === 0) {
      enqueueSnackbar( t('No billing to add'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
        setOpenArticleBillingTypeFilter(false);
      return;
    }         
    
    evaluateNewTransportBillingsAdded(filteredTransportBillingTypes, selectedFilterTransportBillingTypes);
  }

  const evaluateNewTransportBillingsAdded = async (billingTypes: IBillingType[], selectBillingTypes: number[]) => {
    if(billingTypes.some( x => !x.isTransport) ) {
      enqueueSnackbar( t('Only transports can be added here'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }

    console.log(billingTypes);

    const {busDestinationId, isArrival, isDeparture} = getValues();

    const billings2Append = billingTypes.filter(b => selectBillingTypes.includes(b.id || 0) );
    
    const resultRegistration = await evaluateAddedBillings({...getValues(),
                  billings: billings2Append.map( (x) => ({...x, isArrival, isDeparture, billingTypeId: x.id, billingExtensions: []}) ) });
    
    if(!resultRegistration.succeeded) {
      enqueueSnackbar( resultRegistration.messages.join('\n'), { variant: 'error',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 });
      return;
    }

    

    const arrBillings = resultRegistration.data.billings.map( (x) => {
      //const transportBillingType = billings2Append.find(y => y.id === x.billingTypeId);      
      return {...x, isArticle: false, busDestinationId, isArrival, isDeparture  }} );

    if(currentBillingUsage === 'billing')
      (refAppendBillings.current??emptyFunc)( arrBillings);
    else
      (refAppendNewTransportBillings.current??emptyFunc)( arrBillings);

    setOpenTransportBillingTypeFilter(false);
  }
  
  const handleClickOpenBusDestination = async (event: any) => {
    setOpenBusDestinationFilter(true);
  }

  const handleClickOpenPerson = async (event: any) => {
    setOpenPersonFilter(true);
  }

  const handleClickRemovePerson = async (event: any) => {
    setValue('personId', 0);
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('birthPlace', '');
    setValue('civility', '');
    setValue('gender', '');

    setValue('studentCode', '');
  }

  const [openParentPortables, setOpenParentPortables] = useState<boolean>(false);
  const [currentParentPortableIdx, setCurrentParentPortableIdx] = useState<number>(0);
  const [parentPortables, setParentPortables] = useState<IPerson[]>([]);
  const handleClickOpenParentPortables = async (portableIdx: number) => {

    const {personId} = getValues();
    if(personId <= 0) return;

    const prtPortables = await getParentPortables(personId);
    setParentPortables(prtPortables);
    setCurrentParentPortableIdx(portableIdx);
    setOpenParentPortables(true);
  }


  const handlePersonPortableDoubleClick = (event: React.MouseEvent<unknown>,row: IPerson) => {  

    setOpenParentPortables(false);
    if(currentParentPortableIdx === 1)
      setValue('portable1', row.portable);
    else if(currentParentPortableIdx === 2)
    setValue('portable2', row.portable);
  }


  const [openParentEmails, setOpenParentEmails] = useState<boolean>(false);
  const [currentParentEmailIdx, setCurrentParentEmailIdx] = useState<number>(0);
  const [parentEmails, setParentEmails] = useState<IPerson[]>([]);
  const handleClickOpenParentEmails = async (emailIdx: number) => {

    const {personId} = getValues();
    if(personId <= 0) return;

    const prtEmails = await getParentEmails(personId);
    setParentEmails(prtEmails);
    setCurrentParentEmailIdx(emailIdx);
    setOpenParentEmails(true);
  }


  const handlePersonEmailDoubleClick = (event: React.MouseEvent<unknown>,row: IPerson) => {  

    setOpenParentEmails(false);
    if(currentParentEmailIdx === 1)
      setValue('email1', row.email);
    else if(currentParentEmailIdx === 2)
    setValue('email2', row.email);
  }
  
  
  const debouncedNameChange = useRef(
    debounce( async () => {      
      const {personId, firstName, lastName, particularSign} = getValues();

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' ))
        return;

      const count = await getPersonsSearchCount(firstName, lastName, particularSign);

      if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
      
      if(count > 0) 
        refPersonSnackbarId.current = enqueueSnackbar( `${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch(firstName,lastName,particularSign) } );             

    }, 2500)
  ).current;

  

  const refPersonSnackbarId = useRef<SnackbarKey>();
  const xActionPersonSearch = (firstName: string, lastName: string, particularSign:string): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  useEffect( () => {
      debouncedNameChange();    
  }, [watchFirstName, watchLastName, watchParticularSign, debouncedNameChange]); 

  useEffect( () => {              
    async function _() {
      const arr = await getSchoolYearClassTemplates(watchSchoolYearId, false, '', '');

      setSchoolYearClassTemplates( arr.filter( x => x.studyLanguage === watchStudyLanguage) );
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchStudyLanguage]); 

  useEffect( () => {              
    async function _() {
      // const schoolYear = (schoolYears || []).find(sy => sy.id === watchSchoolYearId);
      // const year = isFalsy(schoolYear) ? 0 : schoolYear.year;
      if(watchSchoolYearId > 0 && watchSchoolYearClassTemplateId > 0) {
        const arr = await getSchoolYearClasses({
            schoolYearId: watchSchoolYearId, 
            name: '' ,
            studyType: watchStudyType, 
            studyLanguage:watchStudyLanguage,
            studyLevel: '' });
        
        setClasses(arr.filter(x => x.schoolYearClassTemplateId === watchSchoolYearClassTemplateId && x.isActive) );  
      }    
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchSchoolYearClassTemplateId, watchStudyLanguage  ]); 


  const [schoolYearClassCountStudents, setSchoolYearClassCountStudents] = useState<number>(0);
  const [generateIntialRegistrationLoading, setGenerateIntialRegistration] = useState<boolean>(false);
  useEffect( () => {              
    async function _() {

      const {status, initialSchoolYearClassId} = getValues();    
      
      const schoolYearClass = classes.find(c => c.id === watchSchoolYearClassId);
      
      setSchoolYearClassCountStudents( isFalsy(schoolYearClass)? 0 : schoolYearClass.countStudents);
            
      if(status === '95' && watchSchoolYearClassId > 0 && watchSchoolYearClassId !== initialSchoolYearClassId) {
        try {
          setGenerateIntialRegistration(true);
          const registration = await generateIntialRegistration( {...getValues()});
          // to do, just set billing amount according to billing id and billing type id.
          reset({...registration});
        }catch {

        }finally{
          setGenerateIntialRegistration(false);
        }
        
        
      } else {
        setValue('initialSchoolYearClassId', 0);
      }
      
              
    }

    _();
  }, [watchSchoolYearClassId]); 

  const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
        const studyTypes = (enumItems || []).filter(x => x.enumerationCode === Enum_STUDY_TYPE);
        if(getValues().status === '95' && studyTypes.length === 1)
          setValue('studyType', studyTypes[0].code);
     
    }, [enumItems])


  useEffect( () => {        
    if(getValues().schoolYearId <= 0)
      setValue('schoolYearId', applicationSetup.currentSchoolYearId);
  }, []);


      useEffect( () => {        
        setCurrentFormNameAtom(t('Registration'));
        setCurrentBasicTextFilterProps(basicFilterRegistration);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Registration',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          setCurrentFormNameAtom(`${t('Registration')} - # ${_data.id} # - ${_data.isRepeater? `(${t('Repeater')})`: '' }` );
            reset({..._data, 
              initialSchoolYearClassId: _data.schoolYearClassId,
              isArrival: false, isDeparture: false});
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        setCurrentFormNameAtom(t('Registration'));     
        reset({...defaultRegistration, schoolYearId: applicationSetup.currentSchoolYearId});    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Registration', _id)){
          setIsSaveLoading(false);
          return;
        }
        
        const data = getValues(); 
        if(data.firstName.trim() === '' && data.lastName.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          if(data.billings.length === 0) {
            enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
    
        if(isFalsy(data.studyType)) {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

         mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Registration', _id);
      }

      const [openNewArticleBilling, setOpenNewArticleBilling] = useState<boolean>(false);
      const actionDataCreateNewArticleBillingsRegistration = async (event: MouseEvent<HTMLButtonElement>) => {
        const {status} = getValues();
        if(status !== '10') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        setOpenNewArticleBilling(true);
      }

      const handleOkCreateNewArticleBillingsRegistration = async () => {
        const {id, newArticleBillings} = getValues(); 
        if(newArticleBillings.length === 0) {
          enqueueSnackbar( t('No billing to add'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        mutateNewArticles({id, billings: newArticleBillings});
      }

      const [openNewTransportBilling, setOpenNewTransportBilling] = useState<boolean>(false);
      const actionDataCreateNewTransportBillingsRegistration = async (event: MouseEvent<HTMLButtonElement>) => {
        const {status} = getValues();
        if(status !== '10') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        setOpenNewTransportBilling(true);
      }      

      const handleOkCreateNewTransportBillingsRegistration = async () => {
        const {id, newTransportBillings} = getValues(); 
        if(newTransportBillings.length === 0) {
          enqueueSnackbar( t('No billing to add'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        mutateNewTransports({id, billings: newTransportBillings});
      }

      const [openNewBilling, setOpenNewBilling] = useState<boolean>(false);
      const actionDataCreateNewBillingsRegistration = async (event: MouseEvent<HTMLButtonElement>) => {
        const {status, newBillings} = getValues();
        if(status !== '10') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        setOpenNewBilling(true);
      }      

      const handleOkCreateNewBillingsRegistration = async () => {
        const {id, newBillings} = getValues(); 
        if(newBillings.length === 0) {
          enqueueSnackbar( t('No billing to add'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }

        mutateNewBillings({id, billings: newBillings});
      }

      const [openChangeClass, setOpenChangeClass] = useState<boolean>(false); // for open dialog to select subject exam
      const actionDataChangeClassRegistration = async (event: MouseEvent<HTMLButtonElement>) => {
          
          setOpenChangeClass(true);
        } 

        const handleOkChangeClassAction = async () => {
          const {id, schoolYearClassId} = getValues(); 
          if(schoolYearClassId <= 0) {
            enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            return;
          }
  
          mutateChangeClass({id, newClassId : schoolYearClassId});
        }
        
      
      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('Registration', _id);
      }

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {    
       queryClient.invalidateQueries(['Registration',_id]);        
      await retrieveData('Registration',_id, refetch);        
      reset(_data);               
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('School year'))} - ${t('Study type')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                <Button id='btnActionCreateNewArticleBillingsRegistration' onClick={actionDataCreateNewArticleBillingsRegistration} sx={ {display:'none'}}  />
                                <Button id='btnActionCreateNewTransportBillingsRegistration' onClick={actionDataCreateNewTransportBillingsRegistration} sx={ {display:'none'}}  />
                                <Button id='btnActionCreateNewBillingsRegistration' onClick={actionDataCreateNewBillingsRegistration} sx={ {display:'none'}}  />

                                <Button id='btnActionChangeClassRegistration' onClick={actionDataChangeClassRegistration} sx={ {display:'none'}}  />
                               
                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label={t('Code')} 
                                {...register('registrationCode')} inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='studyType' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="schoolYearId"
                                        label={t('Study type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                                              
                                
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap key={` student code : ${getValues().studentCode}`}
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Student'))} - ${t('Information')} :: ${getValues().studentCode}`}
                              </Typography>                                                       
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().personId}`}>
                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Student Id')} 
                                {...register('personId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { (isFalsy(watchPersonId) ||  watchPersonId<=0) ?
                                        <IconButton color="primary" onClick={handleClickOpenPerson}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={handleClickRemovePerson}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')} inputProps={ {readOnly: (watchPersonId>0) , style: {textTransform: 'none'}}}/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')} inputProps={ {readOnly: (watchPersonId>0) ,style: {textTransform: 'none'}}} />  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="particularSign" label={t('Part. sign')} 
                                {...register('particularSign')}  />                              
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }
                              { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> }
                            </Box>                                                   
                            <Box sx={{ mt: 0.25, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      readOnly={(watchPersonId>0)}
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} 
                                  {...register('birthPlace')}  inputProps={ {readOnly: (watchPersonId>0) }}/> 
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Class'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='studyLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller name='schoolYearClassTemplateId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="classTemplateId"
                                        label={t('Study level')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearClassTemplates && schoolYearClassTemplates.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.classTemplateName}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name='schoolYearClassId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="schoolYearClassId"
                                        label={t('Class')} inputProps={ {readOnly: false}} focused
                                        helperText={isFalsy(schoolYearClassCountStudents)?'':`# ${t('Student')}(s) : ${schoolYearClassCountStudents} `}
                                        >
                                        {classes && classes.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                

                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(35% - 8px)'}}
                                  label={t('Repeater ?')}
                                  control={
                                  <Controller
                                      name='isRepeater'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                <Controller name='studentFrom' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(65% - 8px)'}} id="studentFrom"
                                        label={t('Student comming from')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_STUDENT_FROM, 
                                                                  t('Student comming from'), t('Student comming from'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchStudentFrom) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_STUDENT_FROM, t('Student comming from'), t('Student comming from'),
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_STUDENT_FROM && x.code === watchStudentFrom)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        }>
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDENT_FROM ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_REGISTRATION_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller control={control}
                                  name='validationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Validation date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(17% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(17% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='effectiveDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Effective date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Registration'))} - ${t('Information')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Bus ?')}
                                  control={
                                  <Controller
                                      name='arriveOrGoesByBus'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                              
                              <TextField sx={{width:'calc(37% - 8px)'}} id="portable1" label={`${t('Portable')}(1)`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                InputProps={{                                  
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                      <IconButton color="primary" onClick={() => handleClickOpenParentPortables(1)}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                                                                     
                                    </InputAdornment>
                                ) 
                              }}/>
                              <TextField sx={{width:'calc(38% - 8px)'}} id="portable2" label={`${t('Portable')}(2)`} 
                                {...register('portable2')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                InputProps={{                                  
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                      <IconButton color="primary" onClick={() => handleClickOpenParentPortables(2)}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                                                                     
                                    </InputAdornment>
                                ) 
                              }}/>
                              
                              { openParentPortables && <FormDialog open={openParentPortables} maxWidth='md'
                                  okText={''} cancelText={t('Cancel')} title={t('Portable')} onCancel={()=> {setOpenParentPortables(false);}} 
                                  onClose={()=> {setOpenParentPortables(false);}} onOk={()=> {setOpenParentPortables(false);}}  >
                                      <EnhancedTable<IPerson> rows={parentPortables} 
                                        headCells={[            
                                          {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                          {id:'firstName', label : t('First name'),  display: true, type: 'string', },
                                          {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
                                          
                                          {id:'portable', label : t('Portable'),  display: true, type: 'string', },
                                        ]} 
                                        title={`${t('Portable')}`} objKey='portable' 
                                        //stateSelected={[filteredSchoolYearTemplateSubjects, setFilteredSchoolYearTemplateSubjects]} 
                                        onRowSelected={undefined} rowCheckedMode='single'
                                        onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                        onRowDoubleClick={handlePersonPortableDoubleClick} 
                                        rowActionIcon={undefined}
                                        toolbarActions={[
                                          // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                        ]}
                                      />
                              </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Brunch ?')}
                                  control={
                                  <Controller
                                      name='takeBrunch'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                              <TextField sx={{width:'calc(37% - 8px)'}} id="email1" label={`${t('Email')}(1)`} 
                                {...register('email1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                InputProps={{                                  
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                      <IconButton color="primary" onClick={() => handleClickOpenParentEmails(1)}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                                                                     
                                    </InputAdornment>
                                ) 
                              }}/>
                              <TextField sx={{width:'calc(38% - 8px)'}} id="email2" label={`${t('Email')}(2)`} 
                                {...register('email2')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                InputProps={{                                  
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                      <IconButton color="primary" onClick={() => handleClickOpenParentEmails(2)}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                                                                     
                                    </InputAdornment>
                                ) 
                              }}/>
                              
                              { openParentEmails && <FormDialog open={openParentEmails} maxWidth='md'
                                  okText={''} cancelText={t('Cancel')} title={t('Email')} onCancel={()=> {setOpenParentEmails(false);}} 
                                  onClose={()=> {setOpenParentEmails(false);}} onOk={()=> {setOpenParentEmails(false);}}  >
                                      <EnhancedTable<IPerson> rows={parentEmails} 
                                        headCells={[            
                                          {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                          {id:'firstName', label : t('First name'),  display: true, type: 'string', },
                                          {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
                                          
                                          {id:'email', label : t('Email'),  display: true, type: 'string', },
                                        ]} 
                                        title={`${t('Email')}`} objKey='email' 
                                        //stateSelected={[filteredSchoolYearTemplateSubjects, setFilteredSchoolYearTemplateSubjects]} 
                                        onRowSelected={undefined} rowCheckedMode='single'
                                        onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                        onRowDoubleClick={handlePersonEmailDoubleClick} 
                                        rowActionIcon={undefined}
                                        toolbarActions={[
                                          // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                        ]}
                                      />
                              </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Parent'))} - ${t('_Tutor')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                              
                              <TextField sx={{width:'calc(34% - 8px)'}} id="fatherName" label={`${t('_Father')}`} 
                                {...register('fatherName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="motherName" label={`${t('_Mother')}`} 
                                {...register('motherName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="tutorName" label={`${t('_Tutor')}`} 
                                {...register('tutorName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                        </Stack>                        
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>       
                          { ((getValues().id || 0) > 0) &&  <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1} sx={{ mt: 1, width: '100%' }} flexDirection='row' >
                            <WidgetSummaryBasic title={`- ${t('Amount')}`} paletteColor={theme.palette['info']} 
                              iconName='IoSendSharp' total={getValues().amount} />
                            <WidgetSummaryBasic title={`- ${t('Amount paid')}`} paletteColor={theme.palette['success']} 
                              iconName='IoSendSharp' total={getValues().amountPaid}  />
                            <WidgetSummaryBasic title={`- ${t('Due amount')}`} paletteColor={theme.palette['warning']} 
                              iconName='IoSendSharp' total={getValues().dueAmount}  />
                            <WidgetSummaryBasic title={`- ${t('Unpaid amount')}`} paletteColor={theme.palette['error']} 
                              iconName='IoSendSharp' total={getValues().unpaidAmount} />
                          </Box> }
                          <Box sx={{ mt: 1, width: '100%' }} >
                              { generateIntialRegistrationLoading && <Box sx={{ mt: 1, width: '100%' }}>                                     
                                          <ThreeDots
                                                  color='#00BFFF'
                                                  height={'100%'}
                                                  //width={100}
                                                  //timeout={3000} //3 secs
                                          />        
                                  </Box>                                
                              }
                            <ArrayFieldTableEx<IRegistration,IBilling,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headBillingCells} rowsPathName={`billings` }
                                title={t('Billings')} rowActionIcon={billingRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                onRowDoubleClick={handleBillingDoubleClick}                    
                                refAppend={refAppendBillings as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateBilling as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                refRemove={refRemoveBilling as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={
                                  getValues().status !== '95' ? []: []
                                  // [ { toolTip: `${t('Add')}...`, onClickIcon: handleAddBillings ,icon: AddCircleIcon,  },  
                                  //   { toolTip: `${t('Add')}...`, onClickIcon: handleAddArticleBillings ,icon: AddCircleIcon,  },
                                  // ]
                                }
                              />
                              { openBillingTypeFilter && <FormDialog open={openBillingTypeFilter} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={t('Billing type filter')} onCancel={()=> {setOpenBillingTypeFilter(false);}} 
                                  onClose={()=> {setOpenBillingTypeFilter(false);}} onOk={handleOkBasicTextFilterBillingTypeForm}  >
                                      <BasicTextFilterForm<IBillingType> {...basicFilterBillingType  } />
                              </FormDialog> }
                              { openArticleBillingTypeFilter && <FormDialog open={openArticleBillingTypeFilter} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={t('Article filter')} onCancel={()=> {setOpenArticleBillingTypeFilter(false);}} 
                                  onClose={()=> {setOpenArticleBillingTypeFilter(false);}} onOk={handleOkBasicTextFilterArticleBillingTypeForm}  >
                                      <BasicTextFilterForm<IBillingType> {...basicFilterArticleBillingType  } />
                              </FormDialog> }
                              { openTransportBillingTypeFilter && <FormDialog open={openTransportBillingTypeFilter} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={t('Transport filter')} onCancel={()=> {setOpenTransportBillingTypeFilter(false);}} 
                                  onClose={()=> {setOpenTransportBillingTypeFilter(false);}} onOk={handleOkBasicTextFilterTransportBillingTypeForm}  >
                                      <BasicTextFilterForm<IBillingType> {...basicFilterTransportBillingType  } />
                              </FormDialog> }

                              { openBillingOption && <FormDialog open={openBillingOption} maxWidth='xs' height='35vh'
                                okText='' cancelText='' title={`${t('Billing')} - ${t('Option')}`} onCancel={()=> {}} 
                                onClose={()=> {setOpenBillingOption(false);}} onOk={() => {setOpenBillingOption(false);}}  >
                                    <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                      <Box sx={{ mt: 1, width: '100%' }} key={` ${getValues().billings[billingIndex].deliveryArticleId} `}>
                                        <Controller 
                                            key={`key-${getValues().billings[billingIndex].deliveryArticleId} `}
                                            name={`billings.${billingIndex}.articleOptionName`}                                            
                                            control={control}
                                            render={({ field }) => <TextField
                                              {...field} sx={{width:`calc(100% - 8px)`}} 
                                              inputProps={ {readOnly:  true , autoComplete: 'new-password', style: {textTransform: 'none'} }} 
                                              InputProps={{
                                                endAdornment: 
                                                  <InputAdornment position="end">                            
                                                      <IconButton color="primary" onClick={handleArticleAvailableOption}>
                                                        <ArrowDropDownCircleIcon />
                                                      </IconButton>                            
                                                  </InputAdornment>                                                
                                              }}
                                          />}
                                        />
                                        <Controller 
                                            key={`key-${getValues().billings[billingIndex].deliveryDescription} delivery`}
                                            name={`billings.${billingIndex}.deliveryDescription`}
                                            
                                            control={control}
                                            render={({ field }) => <TextField
                                              {...field} sx={{width:`calc(100% - 8px)`}} 
                                              inputProps={ {readOnly:  true , autoComplete: 'new-password', style: {textTransform: 'none'} }} 
                                              multiline rows={2}
                                            />}
                                        />
                                      </Box>                                    
                                    </Stack>
                            </FormDialog> }
                            { openArticleAvailable && <FormDialog open={openArticleAvailable} maxWidth='md' 
                                okText='' cancelText='' title={`${t('Billing')} - ${t('Extension')}`} onCancel={()=> {}} 
                                onClose={()=> {setOpenArticleAvailable(false);}} onOk={() => {setOpenArticleAvailable(false);}}  >
                                  <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <EnhancedTable<IBillingType> rows={optionAvailableArticles} 
                                          headCells={[            
                                            {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                            //{id:'code', label : t('Code'),  display: true, type: 'string', },
                                            {id:'name', label : t('Name'),  display: true, type: 'string', },

                                            {id:'articleOptionName', label : t('Option'),  display: true, type: 'string', },
                                            {id:'availableQuantity', label : t('Quantity'),  display: true, type: 'numeric', },

                                            //{id:'description', label : t('Description'),  display: true, type: 'string', },
                                            {id:'deliveryDescription', label : t('Description'),  display: true, type: 'string', },
                                            
                                          ]} 
                                          title={`${t('_Leave_')}(s)`} objKey='id' 
                                          rowCheckedMode='single'
                                          onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                          onRowDoubleClick={handleOptionAvailableArticleDoubleClick} 
                                          rowActionIcon={undefined}
                                          toolbarActions={[
                                            // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                          ]}
                                        /> 

                                    </Box>              
                                  </Stack>
                              </FormDialog>
                            }
                              
                              { openBillingExtension && <FormDialog open={openBillingExtension} maxWidth='sm' 
                                okText='' cancelText='' title={`${t('Billing')} - ${t('Extension')}`} onCancel={()=> {}} 
                                onClose={()=> {setOpenBillingExtension(false);}} onOk={() => {setOpenBillingExtension(false);}}  >
                                    <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        {currentBilling &&  <>
                                          {currentBilling.billingExtensions.map( (extension, index) => {
                                              //getValues().policyRisks[0].policyRiskCoverages[0].policyRiskCoverageExtensions[0].value;
                                              //console.log(policyRiskCoverageIndex);
                                              return (
                                                <Box sx={{ mt: 1, width: '100%' }} key={`key-${extension.id} - ${index}`} >
                                                  <ExtensionValueField 
                                                      name={`billings.${billingIndex}.billingExtensions.${index}.value`}
                                                      label={extension.extensionDescription}
                                                      type={extension.type}
                                                      options={getAsOptions(extensionEnumerationItems, extension.enumerationCode)}
                                                      itemsPerRow={2}
                                                  />                                                              
                                                </Box>)
                                              }) }
                                        </> }
                                      </Box>                                    
                                    </Stack>
                            </FormDialog> }
                          </Box>
                          { ((getValues().id || 0) > 0 && getValues().status !== '95' && getValues().registrationMaterials.length > 0) && 
                            <Box sx={{ mt: 3, width: '100%' }} >
                              <ArrayFieldTableEx<IRegistration,IRegistrationMaterial,'id'> 
                                key={`Material -  ${'reportTable.name'}`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headRegistrationMaterialCells} 
                                rowsPathName={`registrationMaterials`}  
                                title={`${t('School material')}`} rowActionIcon={registrationMaterialRowActionIcon}  
                                
                                //onRowSelected={handleRoleEntitySelected}                                                    
                                refAppend={refAppendRegistrationMaterials as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateRegistrationMaterial as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                refRemove={refRemoveRegistrationMaterial as MutableRefObject<(index: number) => void>}
                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                toolbarActions={[
                                  //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddRegistrationMaterials ,icon: AddCircleIcon,  },
                              ]}
                            />
                            </Box> }
                          { ((getValues().id || 0) > 0 && getValues().status !== '95') && <Box sx={{ mt: 3, width: '100%' }} >
                              <EnhancedTable<ITellerOperation> 
                                rows={getValues().tellerOperations} 
                                headCells={[            
                                  //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                  {id:'reference', label : t('Reference'),  display: true, type: 'string'},
                                                                    
                                  {id:'amount', label : t('Amount'),  display: true, type: 'numeric'},
                                  {id:'operationDate', label : t('Operation date'),  display: true, type: 'date'},
                                  {id:'issueDate', label : t('Issue date'),  display: true, type: 'date'},
                                  {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean'},
                                ]} 
                                title={t(`List of payments`)} objKey='operationDate' 
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy='operationDate'
                                
                                onRowDoubleClick={handleTellerOperationDoubleClick}
                                rowActionIcon={tellerOperationRowActionIcon}
                                toolbarActions={[
                                  // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                ]}
                              />
                            </Box> }
                          { openNewArticleBilling && <FormDialog open={openNewArticleBilling} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Article')} ...`} onCancel={()=> {setOpenNewArticleBilling(false);}} 
                                  onClose={()=> {setOpenNewArticleBilling(false);}} onOk={handleOkCreateNewArticleBillingsRegistration}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <ArrayFieldTableEx<IRegistration,IBilling,'id'> 
                                        mainObject={getValues()} fieldKey='id' 
                                        headCells={headNewArticleBillingCells} rowsPathName={`newArticleBillings` }
                                        title={t('New billings')} rowActionIcon={newArticleBillingRowActionIcon}  
                                        //onRowSelected={handleQuerySelected}
                                                            
                                        refAppend={refAppendNewArticleBillings as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                        refUpdate={refUpdateNewArticleBilling as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                        refRemove={refRemoveNewArticleBilling as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={getValues().status !== '10' ? []:[
                                        //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddNewBillings ,icon: AddCircleIcon,  },
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddNewArticleBillings ,icon: AddCircleIcon,  },

                                        ]}
                                      />
                                  </Box>
                                </Stack>      
                              </FormDialog> }
                              { openNewTransportBilling && <FormDialog open={openNewTransportBilling} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Transport')} ...`} onCancel={()=> {setOpenNewTransportBilling(false);}} 
                                  onClose={()=> {setOpenNewTransportBilling(false);}} onOk={handleOkCreateNewTransportBillingsRegistration}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} key={`trans : ${(getValues().newTransportBillings || []).length}`}>                              
                                    <TextField sx={{width:'calc(50% - 8px)'}} id="busDestinationName" label={`${t('Destination')}`} 
                                      {...register('busDestinationName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton color="primary" onClick={handleClickOpenBusDestination}>
                                                <ArrowDropDownCircleIcon />
                                              </IconButton>                                                                                
                                        </InputAdornment>
                                      ) 
                                    }} />                                  
                                    <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                      label={t('Arrival ?')} key={`arr ${(getValues().newTransportBillings || []).length}`}
                                      control={
                                      <Controller
                                          name='isArrival'  
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange}
                                            
                                            inputProps={ {readOnly: (getValues().newTransportBillings || []).length > 0 }} />}                        
                                    />} />
                                    <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                      label={t('Departure ?')} key={`dep ${(getValues().newTransportBillings || []).length}`}
                                      control={
                                      <Controller
                                          name='isDeparture' 
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} 
                                            
                                            inputProps={ {readOnly: (getValues().newTransportBillings || []).length > 0 }}/>}                        
                                    />} />
                                    
                                  </Box>
                                  { openBusDestinationFilter && <FormDialog open={openBusDestinationFilter} maxWidth='md'
                                    okText='' cancelText='' title={t('Bus destination')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBusDestinationFilter(false);}} onOk={()=> {setOpenBusDestinationFilter(false);}}  >
                                        <BasicTextFilterForm<IBusDestination> {...basicFilterBusDestination } />
                                    </FormDialog> }
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <ArrayFieldTableEx<IRegistration,IBilling,'id'> 
                                        mainObject={getValues()} fieldKey='id' 
                                        headCells={headNewTransportBillingCells} rowsPathName={`newTransportBillings` }
                                        title={t('New billings')} rowActionIcon={newTransportBillingRowActionIcon}  
                                        //onRowSelected={handleQuerySelected}
                                                            
                                        refAppend={refAppendNewTransportBillings as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                        refUpdate={refUpdateNewTransportBilling as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                        refRemove={refRemoveNewTransportBilling as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={getValues().status !== '10' ? []:[
                                        //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddNewBillings ,icon: AddCircleIcon,  },
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddNewTransportBillings ,icon: AddCircleIcon,  },

                                        ]}
                                      />
                                  </Box>
                                </Stack>      
                              </FormDialog> }
                              { openNewBilling && <FormDialog open={openNewBilling} maxWidth='sm'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Billing')} ...`} onCancel={()=> {setOpenNewBilling(false);}} 
                                  onClose={()=> {setOpenNewBilling(false);}} onOk={handleOkCreateNewBillingsRegistration}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <ArrayFieldTableEx<IRegistration,IBilling,'id'> 
                                        mainObject={getValues()} fieldKey='id' 
                                        headCells={headNewBillingCells} rowsPathName={`newBillings` }
                                        title={t('New billings')} rowActionIcon={newBillingRowActionIcon}  
                                        //onRowSelected={handleQuerySelected}
                                                            
                                        refAppend={refAppendNewBillings as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                        refUpdate={refUpdateNewBilling as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                        refRemove={refRemoveNewBilling as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={getValues().status !== '10' ? []:[
                                        //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddNewBillings ,icon: AddCircleIcon,  },
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddNewBillings ,icon: AddCircleIcon,  },

                                        ]}
                                      />
                                  </Box>
                                </Stack>      
                              </FormDialog> }
                              { openChangeClass && <FormDialog open={openChangeClass} maxWidth='sm' height='35vh'
                                  okText={` ${t('Save')}`} cancelText={t('Cancel')} title={`${t('Class')} ... `} onCancel={()=> {}} 
                                  onClose={()=> {setOpenChangeClass(false);}} onOk={handleOkChangeClassAction}  >
                                      <Stack flexDirection='column'>
                                        <Box sx={{ mt: 1, width: '100%' }} >
                                          <Controller name='schoolYearClassId' control={control} 
                                              render={ ({field: {onChange, value}}) => (
                                                <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="schoolYearClassId"
                                                  label={t('Class')} inputProps={ {readOnly: false}} focused
                                                  helperText={isFalsy(schoolYearClassCountStudents)?'':`# ${t('Student')}(s) : ${schoolYearClassCountStudents} `}
                                                  >
                                                  {classes && classes.map( 
                                                    (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                                  }
                                                </TextField>
                                              )}
                                          />
                                        </Box>                  
                                      </Stack>
                                  </FormDialog>
                              }
                              { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                        </Stack>
                      </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

