import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import MoreIcon from '@mui/icons-material/More';

import SelectAllIcon from '@mui/icons-material/SelectAll';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import NumberFormat from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';

import { IEntity, IResult } from 'library/interface';

import useRegistrationMarkService from './services/RegistrationMark';

import useUtils from 'library/utils';

import { IRegistrationMark, IRegistrationMarkCompetence, ISchoolYearClassSubjectMark, defaultRegistrationMark, defaultRegistrationMarkCompetence, defaultSchoolYearClassSubjectMark } from './models/RegistrationMark';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, FormControlLabel, FormHelperText, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_DAY_WEEK,
  Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_TEACHER_STATUS, Enum_SUBJECT,
  Enum_REGISTRATION_MARK_PURPOSE, Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS } from 'features/configuration/models/Enumeration';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { ISchoolYearClass, ISchoolYearClassSubject, ISchoolYearClassSubjectExamSchedule, ISchoolYearClassTemplate, ISchoolYearPeriod, ISchoolYearPeriodExam, ISchoolYearTemplateSubjectExamSchedule } from 'features/setup/models/SchoolYear';
import useSchoolYearService, { useBasicFilterSchoolYearClass } from 'features/setup/services/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { typographyGroupBoxStyling } from 'themes/commonStyles';

import { debounce } from 'lodash';
import EnhancedTable from 'components/ui/EnhancedTable';
import BasicImport2Form from 'components/ui/BasicImport2Form';
import { exportToExcelExMultipleSheets } from 'library/xlsx-export';
import { useDownloadTextFile } from 'library/useDownloadTextFile';
//import { useBasicFilterApplicationQuery } from 'features/setup/services/ApplicationQuery';





export const SchoolYearClassSubjectMarkForm: FC<ISchoolYearClassSubjectMark> = (props: ISchoolYearClassSubjectMark = defaultSchoolYearClassSubjectMark) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();

  const {findDuplicate, findDuplicate2, findDuplicate3, parseDate, parseTime} = useUtils();

  const {language: lg, schoolYears} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { displayDownload : displayDownloadErrorLogs } = useDownloadTextFile();

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {saveSchoolYearClassSubjectMark } = useRegistrationMarkService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { getSchoolYearClassTemplates, getSchoolYearClassRegistrationMarks, getSchoolYearClassSubjects,
    getSchoolYearPeriods, getSchoolYearPeriodExams, getSchoolYearTemplateSubjectExamSchedules,
    getSchoolYearClassSubjectExamSchedules } = useSchoolYearService();
  
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  // const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
    
  //   async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {
  //     const {id, name } = row;
      
  //     setIsSearchBoxShow(false);
  //     _setId(row.id);
  //   }
  // );

  const [openClassFilter, setOpenClassFilter] = useState(false);
  const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
    async (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
           
        //const schoolYearClass = await retrieveEntity('SchoolYearClass',row.id) as ISchoolYearClass;
        const {id, studyType, name, schoolYearClassTemplateId,
          classTemplateName, studyLanguage,
          fullTeacherName, substituteTeacherName,
          schoolYearId
          } = row;  
          
        const data = getValues();
        reset({...data, schoolYearClassId: id, 
              schoolYearId, schoolYearClassTemplateId,
          
              schoolYearClassName: name, studyType, 
              fullTeacherName, substituteTeacherName, classTemplateName, studyLanguage,
             })

        setValue('schoolYearId', schoolYearId); 
        
        const arr = await getSchoolYearClassSubjects(id); // schoolYearClassId
        setSchoolYearClassSubjects([...arr]);
        
        const arr2 = await getSchoolYearClassSubjectExamSchedules(id, 0 ,0);
        setSchoolYearClassSubjectExamSchedules(arr2);        

        setOpenClassFilter(false);     
      }
  );


  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          
      }
  );

  

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          
      }
  );
  
    
  const emptyFunc = (obj: any) => {}

  const methods = useForm<ISchoolYearClassSubjectMark>({defaultValues:defaultSchoolYearClassSubjectMark});
  const { register, setValue ,getValues, watch, reset ,handleSubmit, setFocus ,control , formState: { errors } } = methods;

  const watchSchoolYearId = watch('schoolYearId');
  const watchSchoolYearClassId = watch('schoolYearClassId');
  const watchSchoolYearPeriodId = watch('schoolYearPeriodId');
  const watchSchoolYearPeriodExamId = watch('schoolYearPeriodExamId');
  const watchSchoolYearClassSubjectId = watch('schoolYearClassSubjectId');
 
  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYearClassSubjectMark>,Error,ISchoolYearClassSubjectMark>(
      saveSchoolYearClassSubjectMark, {   
        onSuccess: async (data: IResult<ISchoolYearClassSubjectMark>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          // const schoolYearClassSubjectExamSchedule = schoolYearClassSubjectExamSchedules.find(
          //     x => x.schoolYearClassId === data.data.schoolYearClassId &&
          //     x.schoolYearTemplateSubjectExamScheduleId === data.data.schoolYearTemplateSubjectExamScheduleId );

          // setSchoolYearClassSubjectExamSchedules(schoolYearClassSubjectExamSchedules.map( x => {

          //   return {...x,
          //       id: isFalsy(schoolYearClassSubjectExamSchedule)? x.id : schoolYearClassSubjectExamSchedule.id,
          //       status: isFalsy(schoolYearClassSubjectExamSchedule)? x.status : schoolYearClassSubjectExamSchedule.status
          //   }
          // }));
          const arr = await getSchoolYearClassSubjectExamSchedules(data.data.schoolYearClassId, 0 ,0);
          setSchoolYearClassSubjectExamSchedules(arr);

          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYearClassSubjectMark',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });
    
    const {data: _data, refetch} = useQuery<ISchoolYearClassSubjectMark>(['SchoolYearClassSubjectMark', _id], () => retrieveEntity('SchoolYearClassSubjectMark',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: schoolYearClassTemplate } = useQuery<ISchoolYearClassTemplate>(['SchoolYearClassSubjectMark', 'SchoolYearClassTemplate', watchSchoolYearClassTemplateId], 
          () => retrieveEntity('SchoolYearClassTemplate',watchSchoolYearClassTemplateId), {refetchOnWindowFocus: false ,enabled: watchSchoolYearClassTemplateId>0 } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'SchoolYearClassTemplate'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_TEACHER_STATUS, Enum_DAY_WEEK,
            Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_SUBJECT, 
            Enum_REGISTRATION_MARK_PURPOSE, Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS ] ));

    const [schoolYearPeriods, setSchoolYearPeriods] = useState<ISchoolYearPeriod[]>([]);
    const [schoolYearPeriodExams, setSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);

    const [schoolYearClassSubjects, setSchoolYearClassSubjects] = useState<ISchoolYearClassSubject[]>([]);

    const [schoolYearClassSubjectExamSchedules, setSchoolYearClassSubjectExamSchedules] = useState<ISchoolYearClassSubjectExamSchedule[]>([]);

    //const [schoolYearTemplateSubjectExamSchedules, setSchoolYearTemplateSubjectExamSchedules] = useState<ISchoolYearTemplateSubjectExamSchedule[]>([]);

    // const [currentLibrary, setCurrentLibrary] = useState<IExternalLibrary>(defaultExternalLibrary);
    // const [currentLibraryClass, setCurrentLibraryClass] = useState<IExternalLibraryClass>(defaultExternalLibraryClass);

    // const [dbmsType, setDbmsType] = useState<string>('');

    // const [applicationQueryIndex, setApplicationQueryIndex] = useState<number>(-1);
    // const [applicationQueryParameterIndex, setRegistrationMarkIndex] = useState<number>(-1);

    // const [openAppQueryParamDialog, setOpenAppQueryParamDialog] = useState<boolean>(false);
 
    

   const inputLogin = useRef();
   const cellKeyDownMark = (e: React.KeyboardEvent<HTMLInputElement>, row: IRegistrationMark,index: number) => {
    
    if( !['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key) )
      return;

    const cellId = 'mark';
    const rowsPathNameDef = 'registrationMarks';
    const len = getValues().registrationMarks.length;

    const idx = ['Enter', 'ArrowDown'].includes(e.key) ? 
                  Math.min(index+1, len-1) : Math.max(index-1, 0);
    
    const elt = document.getElementById(`${rowsPathNameDef}-id-${cellId}-${idx}`); // This is how id is set in ArrayFeldTableEx
    if(isFalsy(elt))
      return;

    elt!.focus();
    (elt! as HTMLInputElement).select();    
  }

  const cellEditableRegistrationMark = (row: IRegistrationMark, cellId: keyof IRegistrationMark) => {
      
    return row.markExist 
            && ['00','95'].includes(getValues().schoolYearClassSubjectExamScheduleStatus)
            && watchSchoolYearPeriodId > 0 && watchSchoolYearPeriodExamId > 0 &&  watchSchoolYearClassSubjectId > 0;
  }

  const cellAllowedMark = (row: IRegistrationMark, valueMark: number) => {
    if(valueMark > getValues().maxMark)
      enqueueSnackbar( `${t('Invalid mark, the mark must be less than the max allowed' )} : ${getValues().maxMark} !!!`, { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 

    const allowed = valueMark >= 0 && valueMark <= getValues().maxMark;
    debouncedMark();

    return allowed;
  }

  const textDecorationMark = (row: IRegistrationMark, index: number) : string => {

    if(!row.markExist) return '';
    
    if(isFalsy(schoolYearClassTemplate)) return 'line-through';
    
    const schoolYearClassSubject = schoolYearClassSubjects.find( s => s.id === watchSchoolYearClassSubjectId);
    if(isFalsy(schoolYearClassSubject)) return 'line-through';
    
    const schoolYearTemplateSubject = schoolYearClassTemplate.schoolYearTemplateSubjects.find(
            s => s.id === schoolYearClassSubject.schoolYearTemplateSubjectId);
    if(isFalsy(schoolYearTemplateSubject)) return 'line-through';
    
    const schoolYearTemplateSubjectExamSchedule =  schoolYearTemplateSubject.schoolYearTemplateSubjectExamSchedules.find( 
      e => e.schoolYearPeriodExamId === watchSchoolYearPeriodExamId );
    
    if(isFalsy(schoolYearTemplateSubjectExamSchedule)) return 'line-through';
    
    if( schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.length !== 
        row.registrationMarkCompetences.length) return 'line-through';
        
    if(schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.length === 0)
      return '';

    const { maxMark } = getValues();
    
    if(row.registrationMarkCompetences.some(x => x.maxMark <= 0)) return 'line-through';
    
    if(row.registrationMarkCompetences.some(x => x.maxMark < x.mark)) return 'line-through double';

    const sumMark = row.registrationMarkCompetences.filter(c => c.markExist).reduce((acc, curr) => acc + curr.mark , 0);
    const sumMaxMark = row.registrationMarkCompetences.filter(c => c.markExist).reduce((acc, curr) => acc + curr.maxMark , 0);
    
    const markCompetence = (sumMark*maxMark)/sumMaxMark;
    
    if( Math.abs(markCompetence-row.mark) > 0.0001 ) return 'line-through';
    //const registrationMarkCompetences = row.registrationMarkCompetences;

    return '';
  }  

  useEffect(() => {
    
    const subscription = watch( async (value, { name, type }) => {
      
      if( name?.startsWith('registrationMarks') && name?.endsWith('markExist') ) {    
        
        debouncedMark();                          
      } 
    });  

    return () => subscription.unsubscribe();
  }, [watch]);

  const debouncedMark = useRef(
    debounce( () => {
      //const sumAmount = sum( getValues().tellerOperationBillings.map( ({amount}) => amount ) );
      //setValue('amount', sumAmount);

      const marks = getValues().registrationMarks.map( x => x.mark).sort((a,b)=>b-a) ; // desc.
      const markSet = new Set(marks);
      const len = getValues().registrationMarks.length;

      markSet.forEach(function(mark) {

        for(var i=0; i< len; i++) {
          const registrationMark = getValues().registrationMarks[i];

          if(registrationMark.mark !== mark) continue;

          const calcRank = getValues().registrationMarks.filter( x => x.markExist && x.mark > mark).length;
            
          (refUpdateRegistrationMark.current??emptyFunc)(i, {
            ...registrationMark, 
            mark: registrationMark.markExist? mark : 0,
            rank: registrationMark.markExist? calcRank+1 : 0,

            registrationMarkCompetences: registrationMark.registrationMarkCompetences.map(c => ({...c, 
                markExist: registrationMark.markExist? c.markExist : false,
                mark : registrationMark.markExist && c.markExist ? c.mark : 0
              }))
          });   
        }

      });

      
    }, 300)
  ).current;

 
  const [headRegistrationMarkCells, setHeadRegistrationMarkCells]  = useState<HeadCell<IRegistrationMark>[]>([]);
  useEffect(() => {
    setHeadRegistrationMarkCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },

      {id:'status', label : t('Status'),  display: true, type: 'string', width: 10, },

      {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 25},
      {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 25},

      {id:'currentMark', label : t('Cur.'),  display: true, type: 'numeric', width: 10, decimalScale: 2 },
      {id:'mark', label : t('Mark'),  display: true, type: 'numeric', width: 10, decimalScale: 2, 
        isEditable: cellEditableRegistrationMark, handleKeyDown: cellKeyDownMark, isAllowed: cellAllowedMark,
        textDecoration: textDecorationMark },
      {id:'markExist', label : t('Exist ?'),  display: true, type: 'boolean', width: 10, },
      {id:'rank', label : t('Rank'),  display: true, type: 'numeric', width: 10 },
    ]  )
  }, [t,i18n, watchSchoolYearPeriodId, watchSchoolYearPeriodExamId, watchSchoolYearClassSubjectId])

  const refAppendRegistrationMarks = useRef<(value: Partial<FieldArray<ISchoolYearClassSubjectMark>> | Partial<FieldArray<ISchoolYearClassSubjectMark>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRegistrationMark = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassSubjectMark>> ) => void>(null);
  const refRemoveRegistrationMark = useRef<(index: number ) => void>(null);

  const [defineAllMark, setDefineAllMark] = useState<boolean>(false);
  const [selectedRegistrationsIndex, setSelectedRegistrationsIndex] = useState<number[]>([]);
  const [openSameMarkDialog, setOpenSameMarkDialog] = useState<boolean>(false);
  const [sameMark, setSameMark] = useState<number>(0);
  const handleSetMarkForSelected = (event: any) => {  
    if(isFalsy(getValues().registrationMarks.length)) return;
    if(isFalsy(selectedRegistrationsIndex.length)) return;
    if( !['00','95'].includes( getValues().schoolYearClassSubjectExamScheduleStatus) ) return;

    setDefineAllMark(false);
    setOpenSameMarkDialog(true);
  }

  const handleSetMarkForAll = (event: any) => { 
    if(isFalsy(getValues().registrationMarks.length)) return;
    if( !['00','95'].includes( getValues().schoolYearClassSubjectExamScheduleStatus) ) return;

    setDefineAllMark(true);
    setOpenSameMarkDialog(true);
  }

  const handleSetMarkOk = (event: any) => { 
    if(sameMark < 0 || sameMark > getValues().maxMark){
        enqueueSnackbar( `${t('Invalid mark, the mark must be less than the max allowed' )} : ${getValues().maxMark} !!!`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
    }

    if(defineAllMark) {
      for(var k=0; k<getValues().registrationMarks.length; k++) {
        const registrationMark = getValues().registrationMarks[k];
        (refUpdateRegistrationMark.current??emptyFunc)(k, { ...registrationMark, mark: sameMark }); 
      }
    } else { 
      for(var i=0; i<selectedRegistrationsIndex.length; i++) {
        const k = selectedRegistrationsIndex[i];
        if( k<0 || k >= getValues().registrationMarks.length ) continue;

        const registrationMark = getValues().registrationMarks[k];
        (refUpdateRegistrationMark.current??emptyFunc)(k, { ...registrationMark, mark: sameMark }); 
      }
      
    }    

    debouncedMark();
    setOpenSameMarkDialog(false);
  }

  
  const handleDownloadMarks = (event: any) => {
    if(isFalsy(getValues().registrationMarks.length)) return;

    exportToExcelExMultipleSheets(
      [
        { items: getValues().registrationMarks, sheetName: `${t('Mark')}`, 
              columns2Export: ['firstName','lastName','mark'] },
        { items: getValues().registrationMarks.flatMap( ({registrationMarkCompetences}) => registrationMarkCompetences ), 
              sheetName: `${t('Mark')}-${t('Competences')}`, 
              columns2Export: ['firstName','lastName','schoolYearTemplateSubjectCompetenceTitle','mark'] }
      ],
      `${t('Marks')}-${getValues().schoolYearClassName}-${refSchoolYearPeriodExamName.current}-${getValues().schoolYearTemplateSubjectLongName}`
    );
  }

  const [markObjecBasicImport2, setMarkObjecBasicImport2] = useState<
    {first: IRegistrationMark[], second: IRegistrationMarkCompetence[]}>( { first: [], second: [] } );

  const [openBasicImport2Form, setOpenBasicImport2Form] = useState<boolean>(false);
  const handleLoadMarks = (event: any) => {
    if(isFalsy(getValues().registrationMarks.length)) return;
    if( !['00','95'].includes( getValues().schoolYearClassSubjectExamScheduleStatus) ) return;

    setOpenBasicImport2Form(true);
  }

  const handleOKBasicImport2Marks = (event: any) => {

    if(isFalsy(getValues().registrationMarks.length)) return; 
    if( !['00','95'].includes( getValues().schoolYearClassSubjectExamScheduleStatus) ) return;
    
    if(isFalsy(schoolYearClassTemplate)) return;
    
    const schoolYearClassSubject = schoolYearClassSubjects.find( s => s.id === watchSchoolYearClassSubjectId);
    if(isFalsy(schoolYearClassSubject)) return;
    
    const schoolYearTemplateSubject = schoolYearClassTemplate.schoolYearTemplateSubjects.find(
            s => s.id === schoolYearClassSubject.schoolYearTemplateSubjectId);
    if(isFalsy(schoolYearTemplateSubject)) return;
    
    const schoolYearTemplateSubjectExamSchedule =  schoolYearTemplateSubject.schoolYearTemplateSubjectExamSchedules.find( 
            e => e.schoolYearPeriodExamId === watchSchoolYearPeriodExamId );
            
    if(isFalsy(schoolYearTemplateSubjectExamSchedule)) return;

    const sheetRegistrationMarks = markObjecBasicImport2.first; 
    const sheetRegistrationMarkCompetences = markObjecBasicImport2.second; 
    
    const duplicateRegistrationMark = findDuplicate2(sheetRegistrationMarks, 'firstName', 'lastName');
    if(!isFalsy(duplicateRegistrationMark)) {
      enqueueSnackbar( `${duplicateRegistrationMark.firstName} ${duplicateRegistrationMark.lastName} ${t('is more than one, check that and others.')}`, 
        { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
        return;
    }
    
    const duplicateRegistrationMarkCompetence = findDuplicate3(sheetRegistrationMarkCompetences, 'firstName', 'lastName','schoolYearTemplateSubjectCompetenceTitle');
    if(!isFalsy(duplicateRegistrationMarkCompetence)) {
      enqueueSnackbar( `${duplicateRegistrationMarkCompetence.firstName} ${duplicateRegistrationMarkCompetence.lastName} 
          ${duplicateRegistrationMarkCompetence.schoolYearTemplateSubjectCompetenceTitle} ${t('is more than one, check that and others.')}`, 
        { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
        return;
    }

    
    const {registrationMarks} = getValues();

    const errorLogs = [] as string[];

    for(const regMark of sheetRegistrationMarks.filter(r => !isFalsy(r.firstName) || !isFalsy(r.lastName)) ) {

      
      const {firstName, lastName, mark} = regMark;
      const indexRegistrationMark = registrationMarks.findIndex(r => 
                            r.firstName.trim() === firstName.trim() && r.lastName.trim() === lastName.trim());
                            
      if(indexRegistrationMark>=0) {
        const registrationMark = registrationMarks[indexRegistrationMark];
        
        setValue(`registrationMarks.${indexRegistrationMark}.mark`, mark);

        const registrationMarkCompetences = schoolYearTemplateSubjectExamSchedule?.schoolYearTemplateSubjectExamScheduleCompetences.map(
          (c) => {
            
            const markCompetence = registrationMark.registrationMarkCompetences.find(x => x.schoolYearTemplateSubjectExamScheduleCompetenceId === c.id);

            return {...c, 
              id: isFalsy(markCompetence) ? 0 : markCompetence!.id,

              registrationMarkId: registrationMark.id, status: 'N', 
              currentMark: isFalsy(markCompetence) ? 0 : markCompetence!.currentMark, maxMark: c.maxMark, 
              mark: isFalsy(markCompetence) ? 0 : markCompetence!.mark, 
              rank: isFalsy(markCompetence) ? 0 : markCompetence!.rank, 
              markExist: isFalsy(markCompetence) ? true : markCompetence!.markExist, 
              markPurpose: isFalsy(markCompetence) ? '' : markCompetence!.markPurpose, 
              markAppreciationId: isFalsy(markCompetence) ? -1 : markCompetence!.markAppreciationId,
              
              schoolYearTemplateSubjectExamScheduleCompetenceId: c.id,
              schoolYearTemplateSubjectCompetenceDescription: c.schoolYearTemplateSubjectCompetenceDescription,

              firstName: registrationMark.firstName, lastName: registrationMark.lastName,
              schoolYearTemplateSubjectCompetenceTitle: c.title
            }; 
          }) as unknown as IRegistrationMarkCompetence[];

        setValue(`registrationMarks.${indexRegistrationMark}.registrationMarkCompetences`, registrationMarkCompetences);
      } else
          errorLogs.push(`${t('Marks')} :  ${t('Invalid data !!!')} ${firstName} ${lastName}`);
    }
    
    for(const competence of 
            sheetRegistrationMarkCompetences.filter(c => (!isFalsy(c.firstName) || !isFalsy(c.lastName)) 
                                                  && !isFalsy(c.schoolYearTemplateSubjectCompetenceTitle)) ) {

      const {firstName, lastName, schoolYearTemplateSubjectCompetenceTitle, mark} = competence;
            
      const indexRegistrationMark = registrationMarks.findIndex(r => 
          r.firstName.trim() === firstName.trim() && r.lastName.trim() === lastName.trim());

      if(indexRegistrationMark>=0) {
        const registrationMark = registrationMarks[indexRegistrationMark];
        
        const indexCompetence = registrationMark.registrationMarkCompetences.findIndex(c => 
            c.schoolYearTemplateSubjectCompetenceTitle.trim() === schoolYearTemplateSubjectCompetenceTitle.trim());
        if(indexCompetence >= 0) {
          setValue(`registrationMarks.${indexRegistrationMark}.` +
                    `registrationMarkCompetences.${indexCompetence}.mark` as any, mark);
        } else
            errorLogs.push(`${t('Exam')}-${t('Competence')} :  ${t('Invalid data !!!')} ${schoolYearTemplateSubjectCompetenceTitle}`);
      } else
        errorLogs.push(`${t('Mark')}-${t('Competence')} :  ${t('Invalid data !!!')} ${firstName} ${lastName}`);

    }

    if(errorLogs.length > 0)
      displayDownloadErrorLogs(t('Importation file contains errors'), 'error', `${t('Marks')}-${getValues().schoolYearClassName}-${refSchoolYearPeriodExamName.current}-${getValues().schoolYearTemplateSubjectLongName}.txt`, errorLogs )

    setOpenBasicImport2Form(false);
  }

  const registrationMarkRowActionIcon = ( registrationMark: IRegistrationMark) : ActionIconTableRow<ISchoolYearClassSubjectMark,IRegistrationMark> => {
  
    const res: ActionIconTableRow<ISchoolYearClassSubjectMark,IRegistrationMark> = {
      toolTip: 'more',
      icon: MoreVertIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistrationMark) => {     

        if(isFalsy(schoolYearClassTemplate)) return;

        const schoolYearClassSubject = schoolYearClassSubjects.find( s => s.id === watchSchoolYearClassSubjectId);
        if(isFalsy(schoolYearClassSubject)) return;

        const schoolYearTemplateSubject = schoolYearClassTemplate.schoolYearTemplateSubjects.find(
                s => s.id === schoolYearClassSubject.schoolYearTemplateSubjectId);
        if(isFalsy(schoolYearTemplateSubject)) return;

        const schoolYearTemplateSubjectExamSchedule =  schoolYearTemplateSubject.schoolYearTemplateSubjectExamSchedules.find( 
          e => e.schoolYearPeriodExamId === watchSchoolYearPeriodExamId );

        const regMark = getValues().registrationMarks.at(index);
        if(isFalsy(regMark)) return;

        const registrationMarkCompetences = schoolYearTemplateSubjectExamSchedule?.schoolYearTemplateSubjectExamScheduleCompetences.map(
          (c) => {

            const markCompetence = regMark.registrationMarkCompetences.find(x => x.schoolYearTemplateSubjectExamScheduleCompetenceId === c.id);

            return {...c, 
              id: isFalsy(markCompetence) ? 0 : markCompetence!.id,

              registrationMarkId: regMark.id, status: 'N', 
              currentMark: isFalsy(markCompetence) ? 0 : markCompetence!.currentMark, maxMark: c.maxMark, 
              mark: isFalsy(markCompetence) ? 0 : markCompetence!.mark, 
              rank: isFalsy(markCompetence) ? 0 : markCompetence!.rank, 
              markExist: isFalsy(markCompetence) ? true : markCompetence!.markExist, 
              markPurpose: isFalsy(markCompetence) ? '' : markCompetence!.markPurpose, 
              markAppreciationId: isFalsy(markCompetence) ? -1 : markCompetence!.markAppreciationId,
              
              schoolYearTemplateSubjectExamScheduleCompetenceId: c.id,
              schoolYearTemplateSubjectCompetenceDescription: c.schoolYearTemplateSubjectCompetenceDescription,

              firstName: regMark.firstName, lastName: regMark.lastName,
              schoolYearTemplateSubjectCompetenceTitle: c.title
            }; 
        }) as unknown as IRegistrationMarkCompetence[];

        setValue(`registrationMarks.${index}.registrationMarkCompetences`, registrationMarkCompetences);
        // regMark.registrationMarkCompetences
        // schoolYearTemplateSubjectExamSchedule?.schoolYearTemplateSubjectExamScheduleCompetences
       
        setMarkIndex(index);   
        setOpenMarkPurpose(true);         
      }
    }
    return res;
}


const cellKeyDownMarkCompetence = (e: React.KeyboardEvent<HTMLInputElement>, row: IRegistrationMarkCompetence,index: number) => {
    
  if( !['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key) )
    return;

  // const cellId = 'mark';
  // const rowsPathNameDef = 'registrationMarks'; /// to do: look it after ....
  // const len = getValues().registrationMarks.length;

  // const idx = ['Enter', 'ArrowDown'].includes(e.key) ? 
  //               Math.min(index+1, len-1) : Math.max(index-1, 0);
  
  // const elt = document.getElementById(`${rowsPathNameDef}-id-${cellId}-${idx}`); // This is how id is set in ArrayFeldTableEx
  // if(isFalsy(elt))
  //   return;

  // elt!.focus();
  // (elt! as HTMLInputElement).select();    
}

const cellEditableRegistrationMarkCompetence = (row: IRegistrationMarkCompetence, cellId: keyof IRegistrationMarkCompetence) => {
   
  const registrationMark = getValues().registrationMarks.find(x => x.id === row.registrationMarkId);
  if(isFalsy(registrationMark)) return false;

  if(cellId === 'markExist' && registrationMark.markExist) return true;

  return row.markExist 
          && ['00','95'].includes(getValues().schoolYearClassSubjectExamScheduleStatus) ;
}

const cellAllowedMarkCompetence = (row: IRegistrationMarkCompetence, valueMark: number) => {
  
  if(valueMark > row.maxMark)
    enqueueSnackbar( `${t('Invalid mark, the mark must be less than the max allowed' )} : ${row.maxMark} !!!`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 

  const allowed = valueMark >= 0 && valueMark <= row.maxMark;
  //debouncedMark();

  return allowed;  
}

const [headRegistrationMarkCompetenceCells, setHeadRegistrationMarkCompetenceCells]  = useState<HeadCell<IRegistrationMarkCompetence>[]>([]);
  useEffect(() => {
    setHeadRegistrationMarkCompetenceCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },

      {id:'schoolYearTemplateSubjectCompetenceDescription', label : t('Competence'),  display: true, type: 'string', width: 40, },
      {id:'status', label : t('Status'),  display: true, type: 'string', width: 7, },

      {id:'currentMark', label : t('Cur.'),  display: true, type: 'numeric', width: 7, decimalScale: 2 },
      {id:'mark', label : t('Mark'),  display: true, type: 'numeric', width: 7, decimalScale: 2, 
        isEditable: cellEditableRegistrationMarkCompetence, handleKeyDown: cellKeyDownMarkCompetence, 
          isAllowed: cellAllowedMarkCompetence },
      {id:'markExist', label : t('Exist ?'),  display: true, type: 'boolean', width: 12, 
        isEditable: cellEditableRegistrationMarkCompetence },
      {id:'rank', label : t('Rank'),  display: true, type: 'numeric', width: 7 },

      {id:'markPurpose', label : t('Purpose'),  display: true, type: 'numeric', width: 20 },
    ]  )
  }, [t,i18n])

  const refAppendRegistrationMarkCompetences = useRef<(value: Partial<FieldArray<ISchoolYearClassSubjectMark>> | Partial<FieldArray<ISchoolYearClassSubjectMark>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRegistrationMarkCompetence = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassSubjectMark>> ) => void>(null);
  const refRemoveRegistrationMarkCompetence = useRef<(index: number ) => void>(null);


  const handleClickOpenSchoolYearClass = (event: any) => {      
    setOpenClassFilter(true);      
  }

  
  const getSubjectExamStatusList = (row: ISchoolYearClassSubjectExamSchedule, cellId: keyof ISchoolYearClassSubjectExamSchedule, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS);
  }

  const [openSchoolYearClassSubjectExamSchedule, setOpenSchoolYearClassSubjectExamSchedule] = useState<boolean>(false);
  const handleOpenSchoolYearClassSubjectExamSchedule = async (event: any) => {

    const arr = await getSchoolYearClassSubjectExamSchedules(watchSchoolYearClassId, watchSchoolYearPeriodId ,watchSchoolYearPeriodExamId);

    setSchoolYearClassSubjectExamSchedules(arr);   
    setOpenSchoolYearClassSubjectExamSchedule(true);
  };

  const [markIndex, setMarkIndex] = useState<number>(-1);
  const [openMarkPurpose, setOpenMarkPurpose] = useState(false);
  const handleClickOpenMarkPurpose = (event: any) => {      
    setOpenMarkPurpose(true);      
  }

  const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;     
    }, [enumItems]);


useEffect( () => {   
  async function _() {
    
    if(watchSchoolYearId <= 0) return;

    const schoolYear = schoolYears.find(x => x.id === watchSchoolYearId);
    if(isFalsy(schoolYear)) return;

    setValue('schoolYearStartDate', schoolYear.startDate);
    setValue('schoolYearEndDate', schoolYear.endDate);

    const periods = await getSchoolYearPeriods(watchSchoolYearId);    
    setSchoolYearPeriods([...periods]);

    const exams = await getSchoolYearPeriodExams(watchSchoolYearId);    
    setSchoolYearPeriodExams([...exams]);    
  }          

   _();

  }, [watchSchoolYearId]);


  useEffect( () => {              
    if(watchSchoolYearClassSubjectId <= 0) return;

    const schoolYearClassSubject = schoolYearClassSubjects.find(x => x.id === watchSchoolYearClassSubjectId);
    if(isFalsy(schoolYearClassSubject)) return;

    setValue('teacherName', schoolYearClassSubject.teacherName);
    setValue('schoolYearTemplateSubjectLongName', schoolYearClassSubject.schoolYearTemplateSubjectLongName);
  }, [watchSchoolYearClassSubjectId]);


  const refSchoolYearPeriodExamName = useRef<string>('');
  useEffect( () => {              
    async function _() {
      
      const data = getValues(); 

      if(data.schoolYearClassId <= 0 || data.schoolYearClassTemplateId <= 0) {
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00', registrationMarks: []});
        return;
      }

      if(watchSchoolYearPeriodExamId <= 0) {
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00', registrationMarks: []});
        return;
      }

      const schoolYearPeriodExam = schoolYearPeriodExams.find(e => e.id === watchSchoolYearPeriodExamId);
      if(isFalsy(schoolYearPeriodExam)) {
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00', registrationMarks: []});
        return;
      }

      refSchoolYearPeriodExamName.current = schoolYearPeriodExam.name;
    
      // const arr = await getSchoolYearClassSubjectExamSchedules(watchSchoolYearClassId, watchSchoolYearPeriodId ,watchSchoolYearPeriodExamId);
      // setSchoolYearClassSubjectExamSchedules(arr);        

      if(watchSchoolYearClassSubjectId<=0) {
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00', registrationMarks: []});
        return;
      }

      const schoolYearClassSubject = schoolYearClassSubjects.find(x => x.id === watchSchoolYearClassSubjectId);
      if(isFalsy(schoolYearClassSubject)) { console.log({schoolYearClassSubjects, watchSchoolYearClassSubjectId});
        enqueueSnackbar( t('This subject is not configured in the class'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00',  registrationMarks: []});
        return;
      } 

      const schoolYearClassSubjectExamSchedule = schoolYearClassSubjectExamSchedules.find(
        x => x.schoolYearTemplateSubjectId === schoolYearClassSubject.schoolYearTemplateSubjectId &&
             x.schoolYearPeriodExamId === watchSchoolYearPeriodExamId);
  
      if(isFalsy(schoolYearClassSubjectExamSchedule)) {
        console.log({schoolYearClassSubjectExamSchedules, schoolYearClassSubject, watchSchoolYearPeriodExamId});
        enqueueSnackbar( t('There is no exam for this subject at class level'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00',  registrationMarks: []});
        return;
      } 

      const {maxMark, status, id} = schoolYearClassSubjectExamSchedule;
      const registrationMarks = await getSchoolYearClassRegistrationMarks(watchSchoolYearPeriodExamId, watchSchoolYearClassSubjectId );
      
      _setId(id);
      reset({...data, maxMark, schoolYearClassSubjectExamScheduleStatus: status, registrationMarks});

      //const registrationMarks = await getSchoolYearClassRegistrationMarks(watchSchoolYearPeriodExamId, watchSchoolYearClassSubjectId );
    
      //reset({...data, registrationMarks});
        
        // reset({...data, studyType, schoolYearClassId: id, schoolYearClassName: name,
        //   classTemplateName, studyLanguage, fullTeacherName, substituteTeacherName,
        //   schoolYearId: row.schoolYearId,
        //   registrationMarks: (registrations || []).map( x => ({
        //         ...x, 
        //         schoolYearTemplateSubjectExamScheduleId: 0, mark: 0}) )
        //   });
    }          
  
     _();

    
  }, [watchSchoolYearPeriodExamId]);


  useEffect( () => {   
    async function _() {
      
      const data = getValues();
      
      reset({...data, maxMark: 0, schoolYearClassSubjectExamScheduleStatus: '00',  registrationMarks: []});
      
      if(data.schoolYearClassId <= 0) return;

      if(watchSchoolYearPeriodExamId <= 0 || watchSchoolYearClassSubjectId <= 0) {
        enqueueSnackbar( `${t('You have to select exam and subject' )} !!!`, { variant: 'info',
          anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, autoHideDuration : 500 }); 
        return;
      } 
      
      const schoolYearClassSubject = schoolYearClassSubjects.find(x => x.id === watchSchoolYearClassSubjectId);
      
      if(isFalsy(schoolYearClassSubject)) {
        enqueueSnackbar( `${t('This subject is not valid in this context' )} !!!`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      const schoolYearClassSubjectExamSchedule = schoolYearClassSubjectExamSchedules.find(
            x => x.schoolYearTemplateSubjectId === schoolYearClassSubject.schoolYearTemplateSubjectId &&
                 x.schoolYearPeriodExamId === watchSchoolYearPeriodExamId);
      
                 
      if(isFalsy(schoolYearClassSubjectExamSchedule)) { 
        console.log({schoolYearClassSubjectExamSchedules,schoolYearClassSubject, watchSchoolYearPeriodExamId});
        enqueueSnackbar( `${t('This subject have not been scheduled for this exam' )} `, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      const {maxMark, status, id} = schoolYearClassSubjectExamSchedule;
      const registrationMarks = await getSchoolYearClassRegistrationMarks(watchSchoolYearPeriodExamId, watchSchoolYearClassSubjectId );
      
      _setId(id);
      reset({...data, maxMark, schoolYearClassSubjectExamScheduleStatus: status, registrationMarks});
        
    }          
  
     _();
  
    }, [watchSchoolYearClassSubjectId]);


  useEffect( () => {              
    setCurrentFormNameAtom(t('Mark'));  
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    // if(_id > 0)
    //   retrieveData('ApplicationQuery',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
  // if(_data && _data.id > 0) {
  //   reset(_data);
  // }
}, [_data]);


const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultSchoolYearClassSubjectMark);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
  if(!checkEntitySaveAuthorization('SchoolYearClassSubjectMark', _id)){
    setIsSaveLoading(false);
    return;
  }   

  const data = getValues(); 

  if(data.registrationMarks.length <= 0){
    enqueueSnackbar( t('There is no mark to save'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }

  const schoolYearTemplateSubjectExamScheduleIds = 
    Array.from( new Set( data.registrationMarks.map( s => s.schoolYearTemplateSubjectExamScheduleId ) || []) );
  if(schoolYearTemplateSubjectExamScheduleIds.length !== 1) {
    enqueueSnackbar( t('All marks did not belong to the same exam'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  } 

  if(data.registrationMarks.some(x => x.schoolYearTemplateSubjectExamScheduleId <= 0)){
    enqueueSnackbar( t('There is an error in the selection of exam'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }


  if(isFalsy(schoolYearClassTemplate)) {
    enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Class template')}`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }
    
  const schoolYearClassSubject = schoolYearClassSubjects.find( s => s.id === watchSchoolYearClassSubjectId);
  if(isFalsy(schoolYearClassSubject)) {
    enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Subject')}`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }
    
  const schoolYearTemplateSubject = schoolYearClassTemplate.schoolYearTemplateSubjects.find(
          s => s.id === schoolYearClassSubject.schoolYearTemplateSubjectId);
  if(isFalsy(schoolYearTemplateSubject)) {
    enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Subject')} +++`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }
    
  const schoolYearTemplateSubjectExamSchedule =  schoolYearTemplateSubject.schoolYearTemplateSubjectExamSchedules.find( 
    e => e.schoolYearPeriodExamId === watchSchoolYearPeriodExamId );
    
  if(isFalsy(schoolYearTemplateSubjectExamSchedule)) {
    enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Exam')} +++`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }
    

  if(data.registrationMarks.some( r => r.mark !== 0 && 
      r.registrationMarkCompetences.length !== schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.length ) ) {
        enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Competence')} ###`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
    }

    if(data.registrationMarks.some( r => r.mark !== 0 && 
      schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.length !== 0 &&
      r.registrationMarkCompetences.filter(c => c.markExist).length === 0 ) ) {
        enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Competence')} ### ${t('Exist ?')}`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
    }

    if(data.registrationMarks.some( r => !r.markExist && r.registrationMarkCompetences.some(c => c.markExist) ) ) {
        enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Competence')} == ${t('Exist ?')}`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
    }

  
  if( schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.some(c => c.maxMark <= 0) ) {
      enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Competence')} - - ${t('Max mark')}`, { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
  }    

  const { maxMark } = data;

  if( data.registrationMarks.some(c => maxMark < c.mark) ) {
    enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Max')} / ${t('Mark')}`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }

  if( data.registrationMarks.some(r => r.registrationMarkCompetences.some(c => c.maxMark < c.mark) ) ) {
    enqueueSnackbar( `${t('Invalid data !!!')} - ${t('Competence')} - ${t('Max')} / ${t('Mark')}`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
  }

  
  let sumMaxMark = 0;
  if( schoolYearTemplateSubjectExamSchedule.schoolYearTemplateSubjectExamScheduleCompetences.length !== 0 &&
      data.registrationMarks.some( r => r.mark>0 && 
         ( sumMaxMark = r.registrationMarkCompetences.filter(c => c.markExist).reduce((acc, curr) => acc + curr.maxMark , 0) ) !== 0 &&
         Math.abs( r.mark -  
              (r.registrationMarkCompetences.filter(c => c.markExist).reduce((acc, curr) => acc + curr.mark , 0)*maxMark)/sumMaxMark ) > 0.0001)) {
          enqueueSnackbar( `${t('Invalid data !!!')}  - ${t('Mark')} / ${t('Competence')} `, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
      }


    // const sumMark = row.registrationMarkCompetences.reduce((acc, curr) => acc + curr.mark , 0);
    // const sumMaxMark = row.registrationMarkCompetences.reduce((acc, curr) => acc + curr.maxMark , 0);
    
    // const markCompetence = (sumMark*maxMark)/sumMaxMark;

  const schoolYearTemplateSubjectExamScheduleId = schoolYearTemplateSubjectExamScheduleIds[0];
  
  mutate( {...data, schoolYearTemplateSubjectExamScheduleId });
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  
  openEntityActionDrawer('SchoolYearClassSubjectExamSchedule', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
  }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Class')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >

                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(50% - 8px)'}} id="schoolYearClassName" 
                                    label={t('Class')} {...register('schoolYearClassName')} inputProps={ {readOnly: true}} 
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickOpenSchoolYearClass}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }} />
                                <Controller name='studyType' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="studyType"
                                        label={t('Study type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />

                                { openClassFilter && <FormDialog open={openClassFilter} maxWidth='md'
                                    okText={''} cancelText={t('Cancel')} title={t('Classes')} onCancel={()=> {setOpenClassFilter(false);}} 
                                    onClose={()=> {setOpenClassFilter(false);}} onOk={()=> {}}  >
                                        <BasicTextFilterForm<ISchoolYearClass> {...basicFilterSchoolYearClass } />
                                </FormDialog> }
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="classTemplateName" 
                                    label={t('Class template')} {...register('classTemplateName')} inputProps={ {readOnly: true}}  />
                              <Controller name='studyLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: true}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="fullTeacherName" 
                                    label={t('Full teacher')} {...register('fullTeacherName')} inputProps={ {readOnly: true}}  />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="substituteTeacherName" 
                                    label={t('Substitute teacher')} {...register('substituteTeacherName')} inputProps={ {readOnly: true}}  />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Exam')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller control={control}
                                  name='schoolYearStartDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Start date')} 
                                      onChange={onChange} disableOpenPicker readOnly                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                
                                <Controller control={control}
                                  name='schoolYearEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('End date')} 
                                      onChange={onChange} disableOpenPicker readOnly               
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                /> 
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearPeriodId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Period')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriods && schoolYearPeriods.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name='schoolYearPeriodExamId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Exam')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriodExams && schoolYearPeriodExams.filter(x => x.schoolYearPeriodId === watchSchoolYearPeriodId).map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Subject')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearClassSubjectId' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} 
                                        id="schoolYearClassSubjectId"
                                        helperText={ (watchSchoolYearClassId>0 && watchSchoolYearPeriodExamId>0) ?
                                          <FormHelperText>                                    
                                            <Link
                                              href="#"
                                              onClick={handleOpenSchoolYearClassSubjectExamSchedule}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }}
                                            >
                                              {t('Status of marks per subject')}
                                            </Link>
                                          </FormHelperText>: null
                                        }
                                        label={t('Subject')} inputProps={ {readOnly: false}} inputRef={inputLogin} focused >
                                        {schoolYearClassSubjects && schoolYearClassSubjects.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.schoolYearTemplateSubjectLongName}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <TextField sx={{width:'calc(50% - 8px)'}} id="teacherName" 
                                    label={t('Teacher')} {...register('teacherName')} inputProps={ {readOnly: true}}  />
                               { openSchoolYearClassSubjectExamSchedule && <FormDialog open={openSchoolYearClassSubjectExamSchedule} maxWidth='sm'
                                    okText={''} cancelText={''} title={t('Status of marks per subject')} 
                                    onCancel={()=> {setOpenSchoolYearClassSubjectExamSchedule(false);}} 
                                    onClose={()=> {setOpenSchoolYearClassSubjectExamSchedule(false);}} onOk={()=> {}}  >
                                        <Stack flexDirection='column'>
                                          <Box sx={{ mt: 4, width: '100%' }} >
                                            <EnhancedTable<ISchoolYearClassSubjectExamSchedule> 
                                              rows={schoolYearClassSubjectExamSchedules} 
                                              headCells={[            
                                                //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                                
                                                {id:'subjectLongName', label : t('Subject'),  display: true, type: 'string', width: 55},
                                                
                                                {id:'status', label : t('Status'),  display: true, type: 'string', width: 45, getOptions: getSubjectExamStatusList},
                                              ]} 
                                              title={t(`Subject`)} objKey='id' 
                                              stateSelected={undefined} 
                                              onRowSelected={undefined} rowCheckedMode='single'
                                              onRowCheckedSelectChange={undefined} order='desc' orderBy='subjectLongName'
                                              onRowDoubleClick={undefined} 
                                              rowActionIcon={undefined}
                                              toolbarActions={[
                                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                              ]}
                                            />
                                          </Box> 
                                        </Stack>
                                </FormDialog> }
                            </Box> 
                            <Box sx={{ mt: 4, width: '100%' }} >
                              {/* <Controller 
                                  name={`methodName`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="methodName"
                                      label={t('Data preparation method')} inputProps={ {readOnly: false}}>                                
                                      {currentLibraryClass.externalLibraryClassMethods.map( 
                                          (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                        }
                                    </TextField>
                                  )}
                              /> */}
                            </Box>
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={8} component={Paper} >
                        <Stack flexDirection='column'>                            
                            <Box sx={{ mt: 1, width: '100%' }} 
                                key={` ${watchSchoolYearPeriodExamId} ${getValues().maxMark} ${watchSchoolYearClassSubjectId} - 
                                    ${getValues().registrationMarks.map(a => String(a.mark)).join('-')} --
                                    ${getValues().registrationMarks.map(a => String(a.markExist)).join('-')}
                                    ${getValues().registrationMarks.map(a => a.registrationMarkCompetences.map(b => String(b.mark)).join(':')).join('-')}  `} >
                              <ArrayFieldTableEx<ISchoolYearClassSubjectMark,IRegistrationMark,'_id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headRegistrationMarkCells} rowsPathName={`registrationMarks` }
                                title={`${t('Student mark out of')} ${getValues().maxMark} ${t('in')} ${getValues().schoolYearTemplateSubjectLongName}`} 
                                rowActionIcon={registrationMarkRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendRegistrationMarks as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassSubjectMark>> | Partial<FieldArray<ISchoolYearClassSubjectMark>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateRegistrationMark as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassSubjectMark>>) => void>}
                                refRemove={refRemoveRegistrationMark as MutableRefObject<(index: number) => void>}

                                rowCheckedMode='multiple'
                                //onRowCheckedSelectChange={handleRowCheckedSelectChange}
                                stateSelectedIndex={[selectedRegistrationsIndex, setSelectedRegistrationsIndex]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Set same mark for selected students')}...`, onClickIcon: handleSetMarkForSelected ,icon: CheckBoxIcon,  },
                                { toolTip: `${t('Set same mark for all students')}...`, onClickIcon: handleSetMarkForAll ,icon: SelectAllIcon,  },

                                { toolTip: `${t('Download marks as Excel file and prepare for upload')}...`, onClickIcon: handleDownloadMarks,icon: FileDownloadIcon,  },

                                { toolTip: `${t('Upload marks from file')}...`, onClickIcon: handleLoadMarks,icon: FileUploadIcon,  },

                                
                                ]}
                              />        
                              { openMarkPurpose && (markIndex >= 0) && <FormDialog open={openMarkPurpose} maxWidth='md' height='80vh'
                                    okText={t('OK')} cancelText={''} title={`${t('Mark purpose')} / ${t('Marks per competence')}`} onCancel={()=> {setOpenMarkPurpose(false);}} 
                                    onClose={()=> {setOpenMarkPurpose(false);}} onOk={()=> {setOpenMarkPurpose(false);}}  >
                                      <Stack flexDirection='column'>                            
                                        <Box sx={{ mt: 1, width: '100%' }} key={` ... `} >
                                          <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                            label={t('Exist ?')}
                                            control={
                                            <Controller
                                                name={`registrationMarks.${markIndex}.markExist`}
                                                control={control}
                                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                            />} />
                                          { !getValues().registrationMarks[markIndex].markExist &&  
                                            <Controller name={`registrationMarks.${markIndex}.markPurpose`}
                                              control={control}                                     
                                              render={ ({field: {onChange, value}}) => (
                                                <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="markPurpose"
                                                  label={t('Purpose')} inputProps={ {readOnly: false}} focused >
                                                  {enumItems && enumItems.filter( e => 
                                                        e.enumerationCode === Enum_REGISTRATION_MARK_PURPOSE ).map( 
                                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                  }
                                                </TextField>
                                              )}
                                          /> }
                                        </Box>
                                        <Box sx={{ mt: 2, width: '100%' }} 
                                          key={` ${watchSchoolYearPeriodExamId} ${getValues().maxMark} ${watchSchoolYearClassSubjectId} --
                                                ${getValues().registrationMarks[markIndex].registrationMarkCompetences.map(a => String(a.markExist)).join('-') }
                                                ${getValues().registrationMarks[markIndex].registrationMarkCompetences.map(a => String(a.mark)).join('-') }
                                          `}                                           
                                          >
                                          <ArrayFieldTableEx<ISchoolYearClassSubjectMark,IRegistrationMarkCompetence,'_id'> 
                                            mainObject={getValues()} fieldKey='id' 
                                            headCells={headRegistrationMarkCompetenceCells} rowsPathName={`registrationMarks.${markIndex}.registrationMarkCompetences` }
                                            title={`${t('Marks per competence')} ${getValues().maxMark} ${t('in')} ${getValues().schoolYearTemplateSubjectLongName}`} 
                                            //rowActionIcon={registrationMarkRowActionIcon}  
                                            //onRowSelected={handleQuerySelected}
                                                                
                                            refAppend={refAppendRegistrationMarkCompetences as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassSubjectMark>> | Partial<FieldArray<ISchoolYearClassSubjectMark>>[], options?: FieldArrayMethodProps) => void>}
                                            refUpdate={refUpdateRegistrationMarkCompetence as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassSubjectMark>>) => void>}
                                            refRemove={refRemoveRegistrationMarkCompetence as MutableRefObject<(index: number) => void>}

                                            rowCheckedMode='multiple'
                                            //onRowCheckedSelectChange={handleRowCheckedSelectChange}
                                            //stateSelectedIndex={[selectedRegistrationsIndex, setSelectedRegistrationsIndex]}
                                            //displayMore={undefined}
                                            // toolbarActions={[
                                            // { toolTip: `${t('Set same mark for selected students')}...`, onClickIcon: handleSetMarkForSelected ,icon: CheckBoxIcon,  },
                                            // { toolTip: `${t('Set same mark for all students')}...`, onClickIcon: handleSetMarkForAll ,icon: SelectAllIcon,  },
                                            // ]}
                                          /> 
                                        </Box>
                                      </Stack>    
                                </FormDialog> }     

                                { openSameMarkDialog && <FormDialog open={openSameMarkDialog} maxWidth='xs' height='35vh'
                                    okText={t('OK')} cancelText={t('Cancel')} title={t('Set same mark')} onCancel={()=> {setOpenSameMarkDialog(false);}} 
                                    onClose={()=> {setOpenSameMarkDialog(false);}} onOk={handleSetMarkOk}  >
                                      <Stack flexDirection='column'>                            
                                        <Box sx={{ mt: 1, width: '100%' }} key={` ... `} >
                                          
                                                <NumberFormat    
                                                  label={t('Mark')} sx={{width:'calc(100% - 8px)'}}
                                                  allowEmptyFormatting={false} 
                                                  control={control}    
                                                  thousandSeparator={true}
                                                  decimalScale={2}
                                                  onValueChange={ (v) => setSameMark(v.floatValue||0) }
                                                  defaultValue={sameMark}
                                                  value={sameMark}
                                                  customInput={TextFieldRight}                            
                                                />
                                              
                                          
                                        </Box>
                                      </Stack>    
                                </FormDialog> }   

                                { openBasicImport2Form && <FormDialog open={openBasicImport2Form} maxWidth='sm' height='45vh'
                                  okText={t('Import')} cancelText={t('Cancel')} title={`${t('Import')} ....`} onCancel={()=> {setOpenBasicImport2Form(false);}} 
                                  onClose={()=> {setOpenBasicImport2Form(false);}} onOk={handleOKBasicImport2Marks}  >
                                      <BasicImport2Form<IRegistrationMark,IRegistrationMarkCompetence> 
                                        fileNameToLoad={`${t('Marks')}-${getValues().schoolYearClassName}-${refSchoolYearPeriodExamName.current}-${getValues().schoolYearTemplateSubjectLongName}.xlsx`}
                                        stateItems={[markObjecBasicImport2, setMarkObjecBasicImport2]} 
                                        columns={ {
                                          first: ['firstName','lastName','mark'],
                                          second: ['firstName','lastName','schoolYearTemplateSubjectCompetenceTitle','mark'],                                          
                                        }}
                                        defaultItem={{first: defaultRegistrationMark, second: defaultRegistrationMarkCompetence }}
                                        
                                        sheetName={{
                                          first: `${t('Mark')}`, 
                                          second: `${t('Mark')}-${t('Competences')}` }}
                                        />
                                </FormDialog> }         
                            </Box>                            
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
