import { useRecoilValue } from 'recoil';
import { useTranslation  } from 'react-i18next';

import {parse} from 'date-fns';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const { t, i18n } = useTranslation();   
    
    const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

    const sanitizeFileName = (name: string) : string => 
        name.replace(/[/\\?%*:|<>]/g,'_')
            .replace(/\s+/g, ' ')
            .trim();
    


    const getDateFormat = () : string => lg.includes('en')? 'MM/dd/yyyy' : 'dd/MM/yyyy';

    const parseDate = (dateString: string) => {
        const formats = ['yyyy-MM-dd', getDateFormat()];
        
        for(const format of formats) { 
            const parsedDate = parse(dateString, format, new Date()); 
            if(!isNaN(parsedDate.getTime()))
                return parsedDate;
        }
        return null;
    }

    const parseTime = (timeString: string) => {
        const formats = ['HH:mm', 'HH:mm:ss', 'HH:mm a','HH:mm:ss a'];
        
        for(const format of formats) { 
            const parsedTime = parse(timeString, format, new Date()); 
            if(!isNaN(parsedTime.getTime()))
                return parsedTime;
        }
        return null;
    }

    const generateRandomColor = () : string => {
        const r = Math.floor(Math.random() * 256); // Random value for red (0 to 255)
        const g = Math.floor(Math.random() * 256); // Random value for green (0 to 255)
        const b = Math.floor(Math.random() * 256); // Random value for blue (0 to 255)
        return `rgb(${r},${g},${b})`; // Return the color as an RGB string
      };

    //const formatAmount = (num: )  new Intl.NumberFormat('fr-FR').format(total)
        
    const filterDistinctObjects = <T>(array: T[], prop1: keyof T, prop2: keyof T): T[] => {
        const seen = new Set<string>();
        return array.filter(item => {
          const key = `${item[prop1]}|${item[prop2]}`;
          if (seen.has(key)) {
            return false;
          }
          seen.add(key);
          return true;
        });
      };

      function hasDuplicate<T>(arr: T[], prop: keyof T): boolean {
        const seenValues = new Set();
        
        return arr.some(item => {
            const value = item[prop];
            if (seenValues.has(value)) {
                return true; // Duplicate found
            }
            seenValues.add(value);
            return false;
        });
    }

      function findDuplicates<T>(arr: T[], prop: keyof T): T[] {
        const seenValues = new Set();
        const duplicates: T[] = [];
    
        arr.forEach(item => {
            const value = item[prop];
            if (seenValues.has(value)) {
                duplicates.push(item); // Add to duplicates array if already seen
            } else {
                seenValues.add(value);
            }
        });
    
        return duplicates;
    }

    function findDuplicate<T>(arr: T[], prop: keyof T): T | undefined {
      const seenValues = new Set();
        
      arr.forEach(item => {
          const value = item[prop];
          if (seenValues.has(value)) {
              return item;
          } else {
              seenValues.add(value);
          }
      });
  
      return undefined;
  }

  function findDuplicate2<T>(arr: T[], prop1: keyof T, prop2: keyof T): T | undefined {
        const seenValues = new Set();
        
        arr.forEach(item => {
            const value = `${item[prop1]}-|-${item[prop2]}`;
            if (seenValues.has(value)) {
                return item;
            } else {
                seenValues.add(value);
            }
        });

        return undefined;
    }

    function findDuplicate3<T>(arr: T[], prop1: keyof T, prop2: keyof T, prop3: keyof T): T | undefined {
        const seenValues = new Set();
        
        arr.forEach(item => {
            const value = `${item[prop1]}-|-${item[prop2]}-|-${item[prop3]}`;
            if (seenValues.has(value)) {
                return item;
            } else {
                seenValues.add(value);
            }
        });

        return undefined;
    }

    return {
        sanitizeFileName,
        
        getDateFormat,
        parseDate,
        parseTime,

        generateRandomColor,

        filterDistinctObjects,
        hasDuplicate,
        findDuplicate,
        findDuplicate2,
        findDuplicate3,
        findDuplicates
    }

}

export default _;