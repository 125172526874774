

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IRegistration, IRegistrationComplaint, IRegistrationSanction, IRegistrationSearch, IRegistrationSheet } from "../models/Registration";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';


const _ = () => {

    const axios = useAxios(); 

    const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createRegistration = async (registration: IRegistration)  =>       
        await (await axios.post('/api/production/registration/create', registration)).data;       
        
    const updateRegistration = async (registration: IRegistration)  =>       
        await (await axios.post('/api/production/registration/update', registration)).data; 
    
    const getRegistration = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/registration/get-registration/${id}`));
      return await data;
    }
   
    const getRegistrations = async (criteria: IRegistrationSearch, pagination?: IPagination) : Promise<IRegistration[]> => {

      const {schoolYearId, studyType, studyLanguage, schoolYearClassName, firstName, lastName } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/registration/get-registrations?schoolYearId=${schoolYearId}&studyType=${studyType}` + 
          `&studyLanguage=${studyLanguage}&schoolYearClassName=${schoolYearClassName}&firstName=${firstName}&lastName=${lastName}` +
          `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
          
      return await data;
    }

    const getRegistrationsBySchoolYearBus = async (schoolYearBusId: number, startDate: Date, endDate: Date) : Promise<IRegistration[]> => {
      
      const sStartDate = encodeURIComponent(startDate.toISOString().split('T')[0]);
      const sEndDate = encodeURIComponent(endDate.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/registration/get-registrations-by-schoolYearBus?schoolYearBusId=${schoolYearBusId}` + 
            `&sStartDate=${sStartDate}&sEndDate=${sEndDate}`));
          
      return await data;
    }

    const getStudentSheets = async (registrationId: number) : Promise<
          {registrationSanctions: IRegistrationSanction[], 
            registrationDisciplinarySheets: IRegistrationSheet[], 
             registrationObservationSheets: IRegistrationSheet[], 
              registrationAbsenceSheets: IRegistrationSheet[],
               registrationComplaints: IRegistrationComplaint[], }> => {
            const {data} = (await axios.get(`/api/production/registration/get-student-sheets?registrationId=${registrationId}`));
            return await data;
    }
    
    const generateIntialRegistration = async (registration: IRegistration)  =>       
        await (await axios.post('/api/production/registration/generate-intial-registration', registration)).data;  

    // registrationAddedBillings is IRegistration with billingTypeIds: number[]
    const evaluateAddedBillings = async (registrationAddedBillings: any): Promise<IResult<IRegistration>>  =>       
        await (await axios.post('/api/production/registration/evaluate-added-billings', registrationAddedBillings)).data; 
        
    const createNewBillings = async (registrationBillings: any): Promise<IResult<IRegistration>>  =>       
        await (await axios.post('/api/production/registration/create-new-billings', registrationBillings)).data; 

    const createNewArticleBillings = async (registrationArticleBillings: any): Promise<IResult<IRegistration>>  =>       
        await (await axios.post('/api/production/registration/create-new-article-billings', registrationArticleBillings)).data; 

    const createNewTransportBillings = async (registrationTransportBillings: any): Promise<IResult<IRegistration>>  =>       
        await (await axios.post('/api/production/registration/create-new-transport-billings', registrationTransportBillings)).data; 
       
    const changeClass = async (changeClassRequest: any): Promise<IResult<IRegistration>>  =>       
      await (await axios.post('/api/production/registration/change-class', changeClassRequest)).data; 
      
    return {    
      createRegistration,
      updateRegistration,
      getRegistration,
      getRegistrations,

      getRegistrationsBySchoolYearBus,

      getStudentSheets,

      generateIntialRegistration,
      evaluateAddedBillings,

      createNewBillings,
      createNewArticleBillings,
      createNewTransportBillings,

      changeClass      
    } 
}

export default _;

export interface IFilterRegistrationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IRegistration[], React.Dispatch<React.SetStateAction<IRegistration[]>>],
}

const defaultFilterRegistrationOption: IFilterRegistrationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterRegistration = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IRegistration) => void,
                    filterOption?: IFilterRegistrationOption  ) => {

  const { getRegistrations } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterRegistrationOption;

  //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

  const [headRegistrationCells, setHeadRegistrationCells]  = useState<HeadCell<IRegistration>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    
    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20},

    {id:'registrationCode', label : t('Code'),  display: true, type: 'string', width: 10},

    {id:'status', label : t('Status'),  display: true, type: 'string', width: 10},
    {id:'schoolYearClassName', label : t('Class'),  display: true, type: 'string', width: 10},
    {id:'schoolYearName', label : t('School year'),  display: true, type: 'string', width: 10},
    {id:'studyType', label : t('Study type'),  display: true, type: 'string', width: 15},
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
    
    {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
      options: [ ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

    {name: 'studyType', text: t('Study type'), value: ''},
    {name: 'studyLanguage', text: t('Study language'), value: ''},
  
    {name: 'schoolYearClassName', text: t('Class'), value: ''},

    {name: lg.includes('en')?'firstName':'lastName', text: lg.includes('en')?t('First name'):t('Last name'), value: ''},
    {name: lg.includes('en')?'lastName':'firstName', text: lg.includes('en')?t('Last name'):t('First name'), value: ''},            
  ]);
  
  const [filteredRegistrations, ] = useState<IRegistration[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IRegistration[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const schoolYearId = toNumber(temp);
    const studyType = filterElements.find( elt => elt.name === 'studyType')?.value || '';
    const studyLanguage = filterElements.find( elt => elt.name === 'studyLanguage')?.value || '';

    const schoolYearClassName = filterElements.find( elt => elt.name === 'schoolYearClassName')?.value || '';

    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';  
    
    const arr = await getRegistrations( {schoolYearId, studyType, studyLanguage, schoolYearClassName, firstName,lastName}, pagination );
   
    return arr;
  }

  const objKey: keyof IRegistration = 'id';

  return {
    title: `${t('Registration')}(s)`, headCells: headRegistrationCells, objKey,
    filterElements, rows: filteredRegistrations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
