import { IEnumerationItem } from "features/configuration/models/Enumeration"
import { BaseType, DataType } from "features/configuration/models/ExtensionType"
import { IRegistration } from "features/production/models/Registration";
import { ITeacher } from "./Teacher";
import { IBilling } from "features/finance/models/Billing";

export type SubjectScheduleType = 'recurrent' | 'exceptional' ;

export interface ISchoolYear {
    id: number,
    
    year: number,
    name: string,
    startDate: Date,
    endDate: Date,
    isActive: boolean,
    
    schoolYearBillingTypes: ISchoolYearBillingType[],
    schoolYearClassTemplates: ISchoolYearClassTemplate[],
    schoolYearPeriods: ISchoolYearPeriod[],

    schoolYearMaterials: ISchoolYearMaterial[],
    
    currentExtensionAlias: string,    
    currentExtensionDescription: string,
    currentExtensionTypeId: number,
    currentExtensionTypeName: string,

    currentExtensionTypeX: DataType,
    currentExtensionTypeBaseType: BaseType,

    registrationSequenceExpression: string,
    registrationCodeExpression: string,

    studentSequenceExpression: string,
    studentCodeExpression: string,

    schoolYearRegistrationExtensions: ISchoolYearRegistrationExtension[]
  }

  export interface ISchoolYearBillingType {
    id: number,
    schoolYearId: number,
    billingTypeId: number,

    dueDate: Date,
    obligatoryExpression: string,
    defaultAmountExpression: string,

    maxNumberPerRegistration: number,

    startDate: Date,
    endDate: Date,

    schoolYearTarifications: ISchoolYearTarification[],

    billingTypeName: string
  }

  export interface ISchoolYearClassTemplate {
    id: number,
    schoolYearId: number,
    classTemplateId: number,
    isActive: boolean,
    countReportCard: number,
    reportCardMark: number,
    defaultSuccessMark: number,

    appreciationSystemCode: string,

    classTemplateName: string,
    classTemplateDescription: string,

    studyLevel: string,
    studyLanguage: string,

    schoolYearStartDate: Date,
    schoolYearEndDate: Date,

    minReportCard: string,
    maxReportCard: string,

    schoolYearClasses: ISchoolYearClass[],
    schoolYearTemplateSubjects: ISchoolYearTemplateSubject[]
  }

  export interface ISchoolYearTemplateSubject {
    id: number,
    schoolYearClassTemplateId: number,

    classTemplateId: number,
    
    category: string,
    subject: string,

    longName: string,
    shortName: string,

    coefficient: number,   
    reportCard: string,
    maxMark: number,

    reportCardOrder: number,

    schoolYearTemplateSubjectExamSchedules: ISchoolYearTemplateSubjectExamSchedule[],

    schoolYearTemplateSubjectBooks: ISchoolYearTemplateSubjectBook[],
    schoolYearTemplateSubjectCompetences: ISchoolYearTemplateSubjectCompetence[];

    // for display purpose.
    schoolYearTemplateSubjectPeriods: {
        schoolYearPeriodId: number, schoolYearPeriodName: string,
        schoolYearTemplateSubjectExamSchedules: ISchoolYearTemplateSubjectExamSchedule[]} []
  }

  export interface ISchoolYearTemplateSubjectBook {
    id: number,
    schoolYearTemplateSubjectId: number,
    title: string,
    authors: string,
    isbn: string,
    publisher: string,
    publicationDate: Date,
    isOfficial: boolean,
    edition: string,
    language: string
  }

  export interface ISchoolYearTemplateSubjectCompetence {
    id: number,
    schoolYearTemplateSubjectId: number,

    title: string,
    description: string,

    schoolYearTemplateSubjectLongName: string
  }

  export interface ISchoolYearTemplateSubjectExamSchedule {
    id: number,
    schoolYearTemplateSubjectId: number,
    schoolYearPeriodExamId: number,

    examDate: Date,
    startTime: Date,
    endTime: Date,
    maxMark: number,
    
    schoolYearPeriodId: number,
    schoolYearPeriodExamName: string,

    subjectLongName: string,

    examDateInput: string,
    startTimeInput: string,
    endTimeInput: string,

    schoolYearTemplateSubjectExamScheduleCompetences: ISchoolYearTemplateSubjectExamScheduleCompetence[]
  }

  export interface ISchoolYearTemplateSubjectExamScheduleCompetence {
    id: number,
    schoolYearTemplateSubjectExamScheduleId: number,
    schoolYearTemplateSubjectCompetenceId: number,

    schoolYearTemplateSubjectCompetenceDescription: number,
    
    reportCardOrder: number,
    maxMark: number,

    subjectLongName: string,
    schoolYearPeriodExamName: string,
    title: string,
  }

  export interface ISchoolYearPeriod {
    id: number,
    schoolYearId: number,
    
    name: string,

    startDate: Date,
    endDate: Date,

    schoolYearPeriodExams: ISchoolYearPeriodExam[]
  }

  export interface ISchoolYearPeriodExam {
    id: number,
    schoolYearPeriodId: number,
    
    name: string,
    startDate: Date,
    endDate: Date,
    gradeWeight: number,

    markAlias: string,

    schoolYearTemplateSubjectExamSchedules: ISchoolYearTemplateSubjectExamSchedule[]
  }


  export interface ISchoolYearClass {
    id: number,
    schoolYearId: number,
    studyType: string,

    schoolYearClassTemplateId: number,

    classTemplateId: number,

    name: string,

    classroomId: number,
    isActive: boolean,
    fullTeacherId: number,
    substituteTeacherId: number,

    classTemplateName: string,
    isClassTemplateActive: boolean,
    studyLevel: string,
    studyLanguage: string,
    classroomName: string,

    fullTeacherName: string,
    substituteTeacherName: string,

    schoolYearClassSubjects: ISchoolYearClassSubject[],

    registrations?: IRegistration[],

    amount: number,
    dueAmount: number,
    unpaidAmount: number,
    amountPaid: number,

    countStudents: number,
    countGirls: number,
    countBoys: number,

    currentSchoolYearPeriodId?: number,
    currentSchoolYearPeriodExamId?: number,
    currentSchoolYearTemplateSubjectExamScheduleId?: number,

    currentSchoolYearClassSubjectExamSupervisors: ITeacher[],

    currentSchoolYearClassSubjectExamStatusPurpose: string
  }


  export interface ISchoolYearClassSubject {
    id: number,
    schoolYearTemplateSubjectId: number,
    schoolYearClassId: number,
    teacherId: number,

    schoolYearClassName: string,
    teacherName: string,

    schoolYearTemplateSubjectLongName: string,

    // for display purpose.
    schoolYearClassSubjectPeriods: {
      schoolYearPeriodId: number, schoolYearPeriodName: string,
      schoolYearClassSubjectSchedules: ISchoolYearClassSubjectSchedule[]} []

  }

  export interface ISchoolYearClassSubjectSchedule {
    id: number,

    schoolYearClassSubjectId: number,
    schoolYearPeriodId: number,

    startTime: Date,
    endTime: Date,
    type:	SubjectScheduleType,
    dayOfWeek: string,
    scheduledDate: Date,

    description: string // this will describe what to put in list: day of week or specific date.
  }

  export interface ISchoolYearClassSubjectExamSchedule {
    id: number,

    schoolYearTemplateSubjectExamScheduleId: number,
    schoolYearClassId: number,
    status: string,
    statusDate: Date,
    statusPurpose: string,

    subjectShortName: string,
    subjectLongName: string,

    schoolYearTemplateSubjectId: number,
    schoolYearPeriodExamId: number;

    maxMark: number,
    
  }

  export interface ISchoolYearTarification {
    id: number,
    schoolYearBillingTypeId: number,
    studyType: string,
    classTemplateId: number,

    amountExpression: string,
    
    billingTypeName: string,
    classTemplateName: string
  }

  export interface ISchoolYearRegistrationExtension {
    id: number,
    extensionTypeId: number,    
    alias: string,
    description: string,
    schoolYearId: number,

    extensionTypeName: string,
  
    extensionEnumerationItems: IEnumerationItem[],

    type: DataType,
    baseType: BaseType,

    enumerationCode: string
  }

  export interface ISchoolYearMaterial {
    id: number,
    schoolYearId: number,
    schoolMaterialCode: string,
    conditionExpression: string,
    isGivenByStudent: boolean
  }

  export const defaultSchoolYear : ISchoolYear = {
    id: 0,

    year: 0,
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    isActive: true,
  
    schoolYearBillingTypes: [],
    schoolYearClassTemplates: [],
    schoolYearPeriods: [],
    schoolYearMaterials: [],

    currentExtensionAlias: '',    
    currentExtensionDescription: '',
    currentExtensionTypeId: 0,
    currentExtensionTypeName: '',

    currentExtensionTypeX: 'text',
    currentExtensionTypeBaseType: 'string',

    registrationSequenceExpression: '',
    registrationCodeExpression: '',

    studentSequenceExpression: '',
    studentCodeExpression: '',
    
    schoolYearRegistrationExtensions: []
  }

  export const defaultSchoolYearClassTemplate : ISchoolYearClassTemplate = {
    id: 0,
    schoolYearId: 0,
    classTemplateId: 0,
    isActive: true,
    countReportCard: 1,
    reportCardMark: 20,
    defaultSuccessMark: 10,
    appreciationSystemCode: '',

    classTemplateName: '',
    classTemplateDescription: '',

    studyLevel: '',
    studyLanguage: '',

    schoolYearStartDate: new Date(),
    schoolYearEndDate: new Date(),

    minReportCard: '',
    maxReportCard: '',

    schoolYearClasses: [],
    schoolYearTemplateSubjects: []
  }

  export const defaultSchoolYearTemplateSubject : ISchoolYearTemplateSubject = {
    id: 0,
    schoolYearClassTemplateId: 0,

    classTemplateId: 0,
    
    category: '',
    subject: '',

    longName: '',
    shortName: '',

    coefficient: 0,   
    reportCard: '',
    maxMark: 20,

    reportCardOrder: 0,

    schoolYearTemplateSubjectExamSchedules: [],

    schoolYearTemplateSubjectBooks: [],
    schoolYearTemplateSubjectCompetences: [],

    schoolYearTemplateSubjectPeriods: []
  }

  export const defaultSchoolYearClass : ISchoolYearClass = {
    id: 0,
    schoolYearId: 0,
    studyType: '',

    schoolYearClassTemplateId: 0,
    classTemplateId: 0,

    name: '',

    classroomId: 0,
    isActive: true,
    fullTeacherId: 0,
    substituteTeacherId: 0,

    classTemplateName: '',
    isClassTemplateActive: true,
    studyLevel: '',
    studyLanguage: '',
    classroomName: '',

    fullTeacherName: '',
    substituteTeacherName: '',

    schoolYearClassSubjects: [],
    registrations: [],

    amount: 0,
    dueAmount: 0,
    unpaidAmount: 0,
    amountPaid: 0,

    countStudents: 0,
    countGirls: 0,
    countBoys: 0,

    currentSchoolYearPeriodId: 0,
    currentSchoolYearPeriodExamId: 0,
    currentSchoolYearTemplateSubjectExamScheduleId: 0,
    currentSchoolYearClassSubjectExamSupervisors: [],

    currentSchoolYearClassSubjectExamStatusPurpose: ''
  }
  
  export const defaultSchoolYearClassSubject : ISchoolYearClassSubject = {
    id: 0,

    schoolYearTemplateSubjectId: 0,
    schoolYearClassId: 0,
    teacherId: 0,

    schoolYearClassName: '',
    teacherName: '',

    schoolYearTemplateSubjectLongName: '',

    schoolYearClassSubjectPeriods: []
  }

  export const defaultSchoolYearPeriod : ISchoolYearPeriod = {
    id: 0,
    schoolYearId: 0,
    
    name: '',

    startDate: new Date(),
    endDate: new Date(),

    schoolYearPeriodExams: []
  }

  export const defaultSchoolYearTemplateSubjectExamSchedule : ISchoolYearTemplateSubjectExamSchedule = {
    id: 0,
    schoolYearTemplateSubjectId: 0,
    schoolYearPeriodExamId: 0,

    examDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    maxMark: 0,
    
    schoolYearPeriodId: 0,
    schoolYearPeriodExamName: '',

    subjectLongName: '',

    examDateInput: '',
    startTimeInput: '',
    endTimeInput: '',

    schoolYearTemplateSubjectExamScheduleCompetences: []
  }
  

  export const defaultSchoolYearTemplateSubjectCompetence : ISchoolYearTemplateSubjectCompetence = {
    id: 0,
    schoolYearTemplateSubjectId: 0,

    title: '',
    description: '',

    schoolYearTemplateSubjectLongName: ''
  }

  export const defaultSchoolYearTemplateSubjectExamScheduleCompetence : ISchoolYearTemplateSubjectExamScheduleCompetence = {
    id: 0,
    schoolYearTemplateSubjectExamScheduleId: 0,
    schoolYearTemplateSubjectCompetenceId: 0,

    schoolYearTemplateSubjectCompetenceDescription: 0,
    
    reportCardOrder: 0,
    maxMark: 0,

    subjectLongName: '',
    schoolYearPeriodExamName: '',
    title: ''
  }


  export interface ISchoolYearSearch {
    
    year: number,
    name: string
  }

  export interface ISchoolYearClassSearch {
    
    schoolYearId: number,
    name: string,
    studyType: string,
    studyLanguage: string,    
    studyLevel: string,     
  }



  export interface IDashboardSchoolYear {
    countClasses: number,
    countStudents: number,

    amount: number,
    amountPaid: number,
    unpaidAmount: number,
    countUnpaidStudents: number,
    dueAmount: number,
    countDueStudents: number,

    countUpToDateStudents: number,    
    
    classChatLabels: string[],
    countClassStudents: number[],
    countClassUpToDateStudents: number[],
    countClassDueStudents: number[],
    countClassCompletedStudents: number[],

    countStudentsPerGender: {label: string, value: number}[],

    schoolYearClasses: ISchoolYearClass[],

    schoolYearBillingTypes: IBilling[],
    schoolYearBillingCategories: IBilling[]
  }

  export const defaultDashboardSchoolYear : IDashboardSchoolYear = {
    countClasses: 0,
    countStudents: 0,

    amount: 0,
    amountPaid: 0,
    unpaidAmount: 0,
    countUnpaidStudents: 0,
    dueAmount: 0,
    countDueStudents: 0,

    countUpToDateStudents: 0,    
    
    classChatLabels: [],
    countClassStudents: [],
    countClassUpToDateStudents: [],
    countClassDueStudents: [],
    countClassCompletedStudents: [],

    countStudentsPerGender: [],

    schoolYearClasses: [],
    schoolYearBillingTypes: [],
    schoolYearBillingCategories: []
  }


  export interface IDashboardResource {
    articleAmount: number,
    articleAmountPaid: number,
    articleUnpaidAmount: number,

    transportAmount: number,
    transportAmountPaid: number,
    transportUnpaidAmount: number,

    allArticles: IBilling[],

    articles: IBilling[],
    transports: IBilling[],
    transportsByDestination: IBilling[],
  }

  export const defaultDashboardResource : IDashboardResource = {
    articleAmount: 0,
    articleAmountPaid: 0,
    articleUnpaidAmount: 0,

    transportAmount: 0,
    transportAmountPaid: 0,
    transportUnpaidAmount: 0,

    allArticles: [],

    articles: [],
    transports: [],

    transportsByDestination: []
  }